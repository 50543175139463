<template>
  <div>
    <h4>Recently processed tickets</h4>
    <table class="data-table">
      <tr class="data-header">
        <th>Ticket</th>
        <th>Title</th>
        <th>Last activity</th>
      </tr>
      <tr class="data-row" v-for="row in ticketsLog" :key="row.id">
        <td><PortalLink view="ticket" :item="row.ticket_id">{{row.ticket_id}}</PortalLink></td>
        <td>{{row.title}}</td>
        <td>{{formatDateTime(row.last_activity)}}</td>
      </tr>
      <tr class="data-row nodata" v-if="ticketsLog && ticketsLog.length == 0">
        <td colspan="3">No log data found!</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
export default {
  name: 'RecentTickets',
  components: {
    PortalLink
  },
  data() {
    return {
      ticketsLog: null,
      formatDateTime: formatDateTime
    };
  },
  async beforeMount() {
    const userId = this.$route.params.id;

    this.$root.loading = true;
    this.ticketsLog = await fetchFromApi(this, '/api/ticket.recent');
    this.$root.loading = false;
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
