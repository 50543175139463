<template>
    <GenericButton :onClick="onClick" :disabled="disabled" :text="text" :icon="icon" class="btn-positive"></GenericButton>
</template>

<script>
    import GenericButton from "@/components/objects/buttons/GenericButton.vue";

    export default {
        name: "PositiveButton",
        components: {GenericButton},
        props: {
            text: {
                type: String,
                required: false
            },
            onClick: {
                type: Function,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            }
        },
    }
</script>

<style>
    .btn-positive {
        background: transparent;
        color: #fff;
        background: #1a8c1a;
    }

    .btn-positive:hover:enabled {
        background: #229b22;
    }

    .btn-positive:disabled {
        background: #2f652f;
        opacity: 0.5;
        cursor: not-allowed;
    }

    .btn-positive:active:enabled {
        background: #3aa43a;
        color: #fff;
        transform: scale(.98);
    }
</style>
