<template>
  <h4>Service request tickets</h4>
  <table class="data-table">
    <tr class="data-header">
      <th>Ticket</th>
      <th>Title</th>
      <th>Date</th>
      <!-- <th>Updated</th> -->
      <th>Status</th>
    </tr>
    <tr class="data-row" v-for="row in ticketsLog" :key="row.id">
      <td><PortalLink view="ticket" :item="row.id">{{row.id}}</PortalLink></td>
      <td>{{row.title}}</td>
      <td>{{formatDateTime(row.date)}}</td>
      <!-- <td>{{formatDateTime(row.updated)}}</td> -->
      <td>{{row.automation_state}}</td>
    </tr>
    <tr class="data-row nodata" v-if="ticketsLog && ticketsLog.length == 0">
      <td colspan="3">No log data found!</td>
    </tr>
  </table>
</template>

<script>
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
export default {
  name: 'UserTicketsLog',
  components: {
    PortalLink
  },
  data() {
    return {
      ticketsLog: null,
      formatDateTime: formatDateTime
    };
  },
  async beforeMount() {
    const userId = this.$route.params.id;

    this.$root.loading = true;
    this.ticketsLog = await fetchFromApi(this, `/api/user/${userId}/ticketlog`);
    this.$root.loading = false;
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
