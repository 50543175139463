<template>
  <h4>User onboarding log</h4>
  <table class="data-table">
    <tr class="data-header">
      <th style="width: 12em;">Date</th>
      <th style="width: 3em;">EventType</th>
      <th style="min-width: 240px; width: 240px;">Plugin</th>
      <th style="width: 100px; min-width: 100px">Level</th>
      <th style="width: 100%;">Text</th>
    </tr>
    <tr class="data-row" v-for="row in onboardingLog" :key="row.id" :class="[row.level.toLowerCase(), resultType(row)]">
      <td>{{formatDateTime(row.timestamp)}}</td>
      <td>{{row.ticket_id}}</td>
      <td>{{row.plugin}}</td>
      <td>{{row.level}}</td>
      <td class="text">{{row.text}}</td>
    </tr>
    <tr class="data-row nodata" v-if="onboardingLog && onboardingLog.length == 0">
      <td colspan="6">No log data found!</td>
    </tr>
    <tr class="data-row endofdata" v-if="onboardingLog && onboardingLog.length > 0 && cursor == null">
      <td colspan="6">End of data</td>
    </tr>
    <tr class="data-row endofdata" v-if="onboardingLog && onboardingLog.length > 0 && cursor != null">
      <td colspan="6"><button class="btn-new subtle" @click="loadData(cursor)">Show more</button></td>
    </tr>
  </table>
</template>

<script>
import {fetchFromApi, formatDateTime, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js'
export default {
  name: 'UserOnboardingLog',
  data() {
    return {
      onboardingLog: [],
      cursor: null,
      formatDateTime: formatDateTime
    };
  },
  methods: {
    async loadData(cursor = 0) {
      if (cursor === null) {
        return;
      }

      const params = {'cursor': cursor}

      this.$root.loading = true;
      let response = await fetchFromApi(this, `/api/user/${this.$route.params.id}/onboardinglog`, null, params);
      this.onboardingLog = this.onboardingLog.concat(response.data);
      this.cursor = response.next_cursor
      this.$root.loading = false;
    },
    resultType(row) {
      let match = /^plugin:.* result: (\w+)$/.exec(row.text);
      if (!match) {
        return null;
      }
      return match[1];
    },
  },
  async beforeMount() {
    await this.loadData();

    registerInfiniteScoll(() => { if(!this.$root.loading) { this.loadData(this.cursor) } });
  },
  async beforeUnmount() {
    unregisterInfiniteScoll();
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
.data-table {
  td:nth-child(1) { // date column
    min-width: 16ex; // no text wrapping
  }
}

.error {
  color: #F03F18;
}

.warning {
  color: #fbbc04;
}

.NOT_IMPLEMENTED {
  display: none;
}

.data-row.OK .text::before, 
.data-row.SUCCESS .text::before, 
.data-row.SKIP .text::before, 
.data-row.ERROR .text::before
{
  user-select: none;
  padding: .125rem .625rem;
  font-size: .75rem;
  line-height: 1rem;
  font-weight: 500;
  border-radius: .25rem;
  margin-inline-end: .5rem;
}

.data-row.ERROR .text::before
{
  content: 'Error';
  color: rgb(155 28 28 );
  background-color: rgb(253 232 232);
}

.data-row.SKIP .text::before
{
  content: 'Skip';
  color: rgb(86 114 153);
  background-color: rgb(235 236 240);
}

.data-row.OK .text::before
{
  content: 'Ok';
  color: rgb(66 56 157);
  background-color: rgb(229 237 255);
}

.data-row.SUCCESS .text::before
{
  content: 'Success';
  color: rgb(3 84 63 );
  background-color: rgb(222 247 236);
}
</style>