<template>
  <div id="fullpage">
   <div id="fullpage-background">
      <div class="center-block">
        <div class="logo-container">
          <div class="code">404</div>
        </div>
        <div>This page does not exist</div>
        <button id="signin" type="submit" autofocus @click="$router.push('/');">Go back</button>
      </div>
   </div>
  </div>
</template>

<script>
  export default {
    name: 'NotFound404',
  }
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
  @import url('https://fonts.googleapis.com/css2?family=Roboto&display=swap');
  #fullpage {
    font-family: 'Roboto', sans-serif;
  }

  #fullpage-background {
    transition: opacity 0.2s ease-out;
    pointer-events: none;

    background: url("../../../public/static/login-background.png");    
    background-position: center;
    background-size:cover;


    position: fixed;
    
    z-index: 5;
    bottom: 0;
    right: 0;
    left: 0;
    top: 81px;

    pointer-events: all;

    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
  }

  .code {
    font-size: 12vh;
    font-family: omnes-pro, monospace;
    font-weight: 600;
    text-shadow: 3px 3px 27px rgb(11 11 11 / 39%);
  }

  #fullpage .center-block {
    align-items: center;
    display: flex;
    flex-direction: column;
    user-select: none;
    margin-bottom: 7em;
    text-align: center;
    color: white;
  }
   
  #fullpage #signin {
    font-family: "omnes-pro", sans-serif;
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    text-transform: uppercase;
    letter-spacing: .4ex;

    color: white;
    border: 0.3ex solid white;
    border-radius: 1.5ex;
    margin-top: 3em;
    padding: 1em 1.5em;

    background: rgba(0, 0, 0, 0.0);
    background-size: 200% 100%;
    background-position:left bottom;

    display: block;
    transition:background-position 0s;
  }

  #fullpage #signin:hover {
    color: #92E0F3;
    background-color: white;
  }

  #fullpage #signin:focus-visible {
    outline: 2px solid #53C6E8;
  }

</style>
