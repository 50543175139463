<template>
<main v-if="automations">
  <HeadingSubtitle text="Automation Health Status" icon="fa-solid fa-heart-pulse" />
  <hr />
  
  <table class="data-table health2" v-if="automations">
    <tr class="data-header">
      <th style="width: 250px;">Automation</th>
      <th style="width: 200px;">Version</th>
      <th style="width: 200px;">Last start</th>
      <th style="width: 300px;">Last end</th>
      <th style="width: 200px;">Next start</th>
      <th>Health status</th>
    </tr>
    <tr class="data-row"  v-for="row in automations"
        :class="{'highlighted': row.automation=='onboarding' || row.automation=='tickets'}"
        :key="row.user_id" :set="diff = dayjs().diff(dayjs(row.last_success_end), 'minute')">
      <td>{{row.automation}}</td>
      <td><abbr :title="formatDateTime(row.version_date)">{{row.version}}</abbr></td>
      <td :title="formatDateTime(row.last_start)">{{formatRelativeDateTime(row.last_start)}}</td>
      <td :title="formatDateTime(row.last_end)">
        <div v-if="row.last_end != row.last_success_end" :title="formatDateTime(row.last_success_end)">
          {{formatRelativeDateTime(row.last_success_end)}} (last success)
          <div style="color:red">{{formatRelativeDateTime(row.last_end)}} (last failure)</div>
        </div>
        <div v-else>
          {{formatRelativeDateTime(row.last_end)}}
        </div>
      </td>
      <td :title="formatDateTime(row.next_start)">{{formatRelativeDateTime(row.next_start)}}</td>
      <td>
        <div class="status">
          <div style="display: inline-block">
            <span class="ribbon2 r-ok" v-if="row.status == 'OK'">ok</span>
            <span class="ribbon2 r-error" v-else-if="diff">degraded</span>
          </div>
          <div class="status-details" v-if="row.status_details">{{ row.status_details }}</div>
        </div>
      </td>
    </tr>
  </table>
</main>
</template>

<script>
import {fetchFromApi, formatDateTime, formatRelativeDateTime} from '@/services/AppTools.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';

export default {
  name: 'AllAppsView',
  components: {
    HeadingSubtitle
  },
  data() {
    return {
      automations: null,
      dayjs: require('dayjs'), // import date library,
      formatDateTime: formatDateTime,
      formatRelativeDateTime: formatRelativeDateTime
    };
  },
  async beforeMount() {
    this.$root.loading = true;
    this.automations = (await fetchFromApi(this, '/api/automations.health2')).filter(a => a.automation != '');
    this.$root.loading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.health2 {
  .highlighted {
    font-weight: bold;
  }
  abbr {
    text-decoration-thickness: 1px;
  }
  .status {
    display:flex;
    align-items: center;
    div:nth-child(2) {
      min-width: 100px;
    }
  }
  .ribbon2 {
    display: inline-block;
    background-color: #b1cf86;
    width: auto;
    color: white;
    padding: 0.5rem 1rem;
    border-radius: 50px;
    font-weight: 500;
  }

  .r-error {
    background-color: #ea9999;
  }

  td {
    height: 3em;
    box-sizing: border-box;
  }

  .status-details {
    display: inline-block;
    font-size: smaller;
    margin-left: 1em;
    color: #666;
    font-weight: normal;
  }
}
</style>