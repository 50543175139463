<template>
    <header class="helsinki">
      <div> <!-- top row -->
        <!-- breadcrumb -->
        <menu class="breadcrumb">
          <ul><a href="/hcplanning" class="bubble">Headcount Planning</a></ul>
          <ul><span class="focus">Admin</span></ul>
        </menu>

        <!-- user notifications -->
        <div class="notifications">
          <!-- <div class="warn-info permissions-notice"><i class="fa-solid fa-exclamation-triangle"></i> Depending on your permissions, some plans may be hidden from you.</div> -->
          <div class="changes-saved-notice" ref="changesSavedNotice"><i class="fa-solid fa-check"></i>Changes saved!</div>
        </div>
      </div>

      <div> <!-- bottom row -->
        <!-- tab navigation -->
        <nav>
          <div :class="{active: activeTab === 'plans'}">
            <button class="btn-new subtle" @click="switchTab('plans')">Plans</button>
          </div>
          <div :class="{active: activeTab === 'roles'}">
            <button class="btn-new subtle" @click="switchTab('roles')"><i class="fa-solid fa-users"></i>Roles</button>
          </div>
          <!-- <div :class="{active: activeTab === 'global_approvers'}">
            <button class="btn-new subtle" @click="switchTab('global_approvers')">Global Approvers</button>
          </div> -->
          <div :class="{active: activeTab === 'global_admins'}">
            <button class="btn-new subtle" @click="switchTab('global_admins')">Global Admins</button>
          </div>
          <!-- <div :class="{active: activeTab === 'salary_ranges'}">
            <button class="btn-new subtle" @click="switchTab('salary_ranges')">Salary Ranges</button>
          </div> -->
        </nav>
        
        <!-- view toolbar: plans -->
        <div class="toolbar" v-if="activeTab === 'plans'">
          <button class="btn-new outline" @click="this.$router.push('/hcplanning/admin/new')"><i class="fa-solid fa-plus"></i>New plan</button>
        </div>
      </div>
    </header>
    <main class="helsinki">
      <div class="plans-tab" v-if="activeTab === 'plans'">
        <table class="data-table">
          <thead>
            <tr class="data-header">
              <th>Name</th>
              <th>Owner</th>
              <th>SupOrg</th>
              <th>Approvers</th> <!-- Agents -->
              <!-- <th>Approvers</th> -->
              <th>Viewers</th>
              <th>Position Prefix</th>
              <th>Status</th>
            </tr>
          </thead>
          <tbody v-if="allPlans && allPlans.length">
            <tr class="data-row" v-for="plan in allPlans" :key="plan.id">
              <td><i class="fa-solid fa-gear"></i> <PortalLink view="hcplanning/admin" :item="plan.id" >{{ plan.title }}</PortalLink></td>
              <td><RouterLink :to="`/user/${plan.owner}`">
                {{ plan.owner_first_name ? `${plan.owner_first_name} ${plan.owner_last_name}` : plan.owner }}
              </RouterLink></td>
              <td>{{ plan.sup_orgs.map(x=>x[1]).join(', ') }}</td>
              <td><span class="user-entry" v-for="item in plan.agents" :key="item[1]"><PortalLink view="user" :item="item[0]" >{{ item[1] }}</PortalLink></span></td>
              <!-- <td><span class="user-entry" v-for="item in plan.approvers" :key="item[1]"><PortalLink view="user" :item="item[0]" >{{ item[1] }}</PortalLink></span></td> -->
              <td><span class="user-entry" v-for="item in plan.viewers" :key="item[1]"><PortalLink view="user" :item="item[0]" >{{ item[1] }}</PortalLink></span></td>
              <td>{{ plan.extra_data.position_prefix }}</td>
              <td>{{ plan.status }}</td>
            </tr> 
          </tbody>
          <tr v-else>
            <td colspan="4" class="no-subplans-notice">No subplans found.</td>
          </tr>
        </table>
      </div>

      <div class="ta-tab ta-data" v-if="activeTab === 'roles'">
        <table class="data-table">
          <thead>
            <tr class="data-header">
              <th style="width: 250px;">User</th>
              <th style="width: 100px;">Country</th>
              <th style="width: 100px;">Role</th>
              <th></th>
            </tr>
          </thead>
  
          <tbody>
            <tr class="data-row" v-for="row in taLeads" :key="row.id">
              <td>{{ row.user_name }}</td>
              <td>{{ row.country_name }}</td>
              <td>{{ row.role }}</td>
              <td><button class="btn-new subtle-icon compact" @click="deleteRole(row, taLeads).then(r => taLeads = r)"><i class="fa-solid fa-trash"></i></button></td>
            </tr>
            <tr class="data-row">
              <td>
                <SelectUserInput :value="newTaLeadSelect.user_id" :text="newTaLeadSelect.user_name || 'Select user...'"
                  dataCacheKey="hcp_hiringmanagers" dataUrl="/api/hcp/0/hiringmanagers"
                  @select="newTaLeadSelect.user_id = $event.id; newTaLeadSelect.user_name = $event.name"></SelectUserInput>
              </td>
              <td>
                <select v-model="newTaLeadSelect.country" v-if="allCountries">
                  <option v-for="c in allCountries" :key="c.id" :value="c.id" :selected="c.id === newTaLeadSelect.country">{{ c.value }}</option>
                </select>
              </td>
              <td>
                <select v-model="newTaLeadSelect.role">
                  <option value="TA_LEAD" selected>TA Lead</option>
                </select>
              </td>
              <td>
                <button class="btn-new" @click="addRole(newTaLeadSelect, taLeads, newTaLeadSelect.role); newTaLeadSelect={}"><i class="fa-solid fa-plus"></i> Add</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>

      <!-- Global Admins -->
      <div class="ta-tab ta-data" v-if="activeTab === 'global_admins'">
        <span style="font-size: large; color:red">Warning - non-functional UI prototype.</span>
        <table class="data-table">
          <thead>
            <tr class="data-header">
              <th style="width: 250px;">User</th>
              <th></th>
            </tr>
          </thead>
  
          <tbody>
            <tr class="data-row" v-for="row in global_admins" :key="row.id">
              <td>{{ row.user_name }}</td>
              <td><button class="btn-new subtle-icon compact" @click="deleteRole(row, global_admins).then(r => global_admins = r)"><i class="fa-solid fa-trash"></i></button></td>
            </tr>
            <tr class="data-row">
              <td>
                <SelectUserInput :value="newAdminSelect.user_id" :text="newAdminSelect.user_name || 'Select user...'"
                  dataCacheKey="hcp_hiringmanagers" dataUrl="/api/hcp/0/hiringmanagers"
                  @select="newAdminSelect = {user_id: $event.id, user_name: $event.name, role: 'ADMIN'}"></SelectUserInput>
              </td>
              <td>
                <button class="btn-new" @click="addRole(newAdminSelect, global_admins); newAdminSelect={}"><i class="fa-solid fa-plus"></i> Add</button>
              </td>
            </tr>
          </tbody>
        </table>
      </div>
      <div class="ta-tab ta-data" v-if="activeTab === 'global_approvers' || activeTab === 'salary_ranges'">
        <span style="font-size: large; color:red">Not yet implemented.</span>
      </div>
    </main>
  </template>
  
  <script>
    import PortalLink from '@/components/PortalLink.vue';
    import SelectUserInput from "@/components/objects/inputs/SelectUserInput.vue";
    import { deleteFromApi, fetchFromApi, postToApi, putToApi } from "@/services/AppTools";
    import { permissions } from "@/services/Permissions";
    export default {
      name: "HcAllPlansView",
  
      components: {
        SelectUserInput,
        PortalLink
      },
  
      data() {
        return {
          allPlans: [],

          roles: [],
          allCountries: [],
          
          taLeads: [],
          newTaLeadSelect: {},

          global_admins: [],
          newAdminSelect: {},

          allFieldsCorrect: true,
          activeTab: "plans",
  
          permissions,
        }
      },
  
      methods: {
        async switchTab(tab) {
          if (tab === this.activeTab) return;
          if (tab === "plans") {
            this.fetchPlans();
          }

          if (tab === "roles") {
            this.taLeads = await fetchFromApi(this, "/api/hcp/roles?type=TA_LEAD");
          }

          if (tab === "global_approvers") {
            // this.fetchTAs().then(tas => this.TAs = tas);
          }

          if (tab === "global_admins") {
            this.global_admins = await fetchFromApi(this, "/api/hcp/roles?type=ADMIN");
          }
          this.activeTab = tab;
        },

        async addRole(role, collection) {
          const result = await postToApi(this, "/api/hcp/roles", role);
          role.id = result.id;
          role.country_name = result.country_name;

          collection.push(role);
        },

        async deleteRole(role, collection) {
          await deleteFromApi("/api/hcp/roles/" + role.id);
          return collection.filter(item => item.id != role.id);
        },

        async fetchPlans() {
          if (!permissions.user) {
            // wait
            return setTimeout(() => this.fetchPlans(), 100);
          }    
          this.allPlans = await fetchFromApi("GET", "/api/hcp/admin");

          let i = 0;
          for (let plan of this.allPlans) {

            // parse extra_data, fallback to empty object
            if (typeof plan.extra_data === "string") {
              plan.extra_data = plan.extra_data ? JSON.parse(plan.extra_data) : {};
            } else {
              plan.extra_data = plan.extra_data || {};
            }


            if (plan.child_plans) {
                for (let child of plan.child_plans) {
                  // check if extra_data is a string, if so, parse it, fallback to empty object
                  if (typeof child.extra_data === "string") {
                    child.extra_data = child.extra_data ? JSON.parse(child.extra_data) : {};
                  } else {
                    child.extra_data = child.extra_data || {};
                  }
                    
                  // insert child plan to allPlans right after its parent
                  this.allPlans.splice(i+1, 0, child);
                }
            }

            // if id not numeric
            if (isNaN(plan.id)) {
              // remove it from allPlans
              this.allPlans.splice(i, 1);
              i--;
            }

            i++;
          }

          setTimeout(this.findInvalidFields, 750);
        },
      },
  
      async beforeMount() {
        this.$root.appClass["page-helsinki"] = true;
        this.fetchPlans();

        const editValues = await fetchFromApi("GET", `/api/hcp/${this.planId}/editvalues`, null, {"countries": true});
        this.allCountries = editValues.countries;
      },
      async beforeUnmount() {
        this.$root.appClass["page-helsinki"] = false;
      },
    }
  </script>
  
  <style scoped lang="scss">
    // @import "~@/styles/helsinki.scss"; // import theme

    .plans_data {
      position: relative;
    }

    .positions-management-btns .float-right {
      display: inline-block;
      vertical-align: top;
    }

    h4.subtitle {
      font-weight: 900;
      font-size: 20px;
      margin-bottom: 1ex;
      margin-top: 2em;
    }

    .data-table {
      margin-top: 0ex;
  
      tr {
        transition: all .2s ease-in-out;
      }
  
      tr.closed {
        color: #999;
      }

      .child-plan {
        background: #f6f6f6;
      }

      th {
        padding-top: 1.5em;
        height: 2.2em;
      }
    }
    
    .warn-info, .changes-saved-notice {
    background: var(--theme-accent);
    padding: 1.05em;
    margin-left: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
    font-size: .8rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    width: auto;

    p {
      margin: 0;
    }

    i {
      margin-right: 1em;
    }
  }

  .warn-info.permissions-notice {
    position: absolute;
    // right: 47.5px;
  }

  .changes-saved-notice {
    background: #4caf50;
    color: #fff;

    transition: all ease .2s;
    opacity: 0;
  }

  td input, td select {
    height: 3em;
    padding: 0 .5em;
    border: 1px solid #ddd;
  }

  td select {
    height: 3.5em; /* padding doesn't work with select */
  }

  .invalid-field, :required:placeholder-shown {
    background: #ffdddd;
    border: 1px solid #ff0000;
  }

  button:disabled {
    cursor: not-allowed;
  }

  button:disabled:focus {
    opacity: 1;
    transform: unset;
  }

  .ownerSelect {
    min-width: 200px;
  }

  .delete-new-row-btn {
    cursor: pointer;
    transition: all ease .1s;

    &:hover {
      color: #ff0000;
      transform: scale(1.1) rotate(5deg);
    }

    &:active {
      transform: scale(1.1) rotate(-5deg);
    }
  }

  .data-table {
    td {
      height: 53px;
      box-sizing: border-box;
    }
  }

  span.user-entry {
    &:not(:first-child)::before {
      content:",";
      margin-right: 1ex;
    }
  }
  
  </style>
