<template>
  <span v-if="readonly">{{modelValue}}</span>
  <div class="editable" @click="isEdit=true" v-else-if="!isEdit">{{modelValue}}</div>
  <div class="edit" v-else-if="type=='date'"><input ref="editdate" class="subtle" type="date" :value="modelValue"><button @click="save($event)" title="Save edit" class="subtle"><i class="fa-solid fa-check"></i></button><button @click="cancel()" title="Cancel edit" class="subtle"><i class="fa-solid fa-xmark"></i></button></div>
  <div class="edit" v-else><input ref="edittext" class="subtle" type="text" :value="modelValue"><button @click="save($event)" title="Save edit" class="subtle"><i class="fa-solid fa-check"></i></button><button @click="cancel()" title="Cancel edit" class="subtle"><i class="fa-solid fa-xmark"></i></button></div>
</template>

<script>
  export default {
    name: 'EditableProp',
    props: ['modelValue', 'type', 'readonly'],
    emits: ['update:modelValue', 'updated'],
    data() {
      return {
        isEdit: false
      };
    },
    methods: {
      save(event){
        if (this.type=='date'){
          this.$emit('update:modelValue', this.$refs.editdate.value)
        }
        else {
          this.$emit('update:modelValue', this.$refs.edittext.value)
        }
        this.isEdit = false;
        this.$emit('updated')
      },
      cancel() {
        this.isEdit = false;
      }
    }
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
  .edit {
    display: inline-block;
  }
  
  .editable {
    min-width: 4em;
    height: 1.3em;
    display: inline-block;
  }
  .editable:hover {
    cursor: pointer;
    /* border: 1px #ddd solid;
    border-radius: 3px; */
  }
  .editable:hover::after {
    content: ' ✎'
  }
</style>