<template>
  <div class="error-overlay">
    <div style="height: 42px; display: flex;justify-content: left; padding: 15px;position: fixed;
    top: 0;
    right: 0;">
      <button class="subtle-gray" style="color:white" title="Close overlay" @click="$emit('close')">
        <i class="fa-solid fa-xmark"></i>
      </button>
    </div>
    <div class="error-msg">{{error}}</div>
  </div>
</template>

<script>
  export default {
    name: 'ErrorOverlay',
    props: ['error'],
    emits: ['close'],
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 
.error-overlay {
  display: flex;
  position: fixed;
  top:0;
  left:0;
  opacity: 0.9;
  background: red;
  height: 100%;
  width:100%;
  z-index: 1000;
  animation: .2s ease-out fadeIn;
  background: rgba(30, 30, 30, 0.99);
  padding: 2em;
}

.error-msg {
  font-family: consolas;
  color: red;
}

.error-overlay button.subtle-gray:hover {
  background-color: rgba(16, 16, 16, 1);
}
</style>