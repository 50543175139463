<template>
  <div class="dropdown" v-click-outside="abort">
    <div class="search" :class="{show: show}">
      <input type="text" :placeholder="placeholderText" id="myInput" autocomplete="off"
        v-model="filterValue" :class="{'focused': show}"
        @focus="focus" @keyup="keyUp" @keydown="keyDown" ref="input">
        <i v-if="!show" @click="$refs.input.focus()" :class="chevron"></i>
        <i v-else @click="abort" class="fa-solid fa-xmark"></i>
    </div>
    <div v-if="show && searchResult && searchResult.length" id="myDropdown" class="dropdown-content" :class="{above: resultsAboveInput}">
      <div>{{ text }}</div>
      <div>
        <span v-for="(item, index) in searchResult" :key="item.id"
          @click="select(index)" :class="{'selected': index==selectedResult}">

          <slot :item="item">{{item.value}}</slot>
        </span>
      </div>
    </div>

    <!-- <div v-if="show" class="transparent-overlay" @click="abort"></div> -->
  </div>
</template>

<script>
  export default {
    name: "FilterDropdown",
    props: ["value", "text", "items", "customChevron", "customFilter"],
    emits: ["select"],
    watch: {
      text: function(newVal, oldVal) { // watch changes in text property
        this.filterValue = newVal;
      }
    },
    data() {
      return {
        show: false,
        filterValue: this.text,
        placeholderText: "",
        searchResult: [],
        chevron: this.customChevron || "fa-solid fa-chevron-down",
        selectedResult: -1,
        startY: 0,
        resultsAboveInput: false,
      };
    },
    methods: {
      focus() {
        this.filterValue = "";
        this.showResults();
      },
      showResults() {
        if(this.show)
          return;

        var domRect = this.$refs.input.getBoundingClientRect();
        var spaceBelow = window.innerHeight - domRect.bottom;

        this.resultsAboveInput = spaceBelow < 400;

        this.selectedResult = -1;
        this.show = true;
        this.placeholderText = this.text ? this.text : "Search...";
        window.addEventListener('scroll', this.handleScroll);
      },
      reset() {
        this.abort();
        this.filterValue = ""
      },
      abort() {
        this.show = false;
        this.filterValue = this.text;
        this.selectedResult = -1;
        this.searchResult = this.items;
        this.placeholderText = "";
        window.removeEventListener('scroll', this.handleScroll);
      },
      select(itemIndex) {
        if(!this.searchResult || itemIndex < 0 || itemIndex >= this.searchResult.length) {
          return this.abort();
        }

        let item = this.searchResult[itemIndex];

        this.filterValue = item.value;
        this.$emit('select', item);
        this.show = false;
        this.placeholderText = "";
        window.removeEventListener('scroll', this.handleScroll);
      },
      keyDown(event) {
        switch (event.code) {
          case "Tab":
            return this.select(this.selectedResult);
        }
      },
      keyUp(event) {
        switch (event.code) {
          case "Control":
            return;
          case "Escape":
            return this.abort();
          case "ArrowDown":
            this.showResults();
            this.selectedResult = Math.min(this.selectedResult + 1, this.searchResult.length - 1);
            return
          case "ArrowUp":
          this.showResults();
            this.selectedResult = Math.max(this.selectedResult - 1, 0);
            return
          case "Enter":
          case "NumpadEnter":
            return this.select(this.selectedResult);
        }

        this.showResults();
        this.filterFunction();
      },

      async filterFunction() {

        // invoke custom filter
        if (this.customFilter) {
          this.selectedResult = 0;
          this.searchResult = await this.customFilter(this.filterValue);
          return
        }

        // default filter
        if (!this.items) return [];
        if (this.filterValue.length === 0){
          this.searchResult = this.items;
          return
        }

        this.selectedResult = 0;
        let filter2 = this.filterValue.toUpperCase();
        let multifilter = filter2.split(" ");

        this.searchResult = this.items.filter(item => {
          return multifilter.every(filter => {
            return item.value.toUpperCase().indexOf(filter) > -1;
          });
        });
      },
      handleScroll() {
        this.abort();
      },
    },
    async beforeMount() {
      this.displayValueDefault = this.text;
      this.searchResult = this.items;
    },
  };
</script>

<style lang="scss" scoped>
  @import "~@/styles/_variables.scss";

  #myInput {
    box-sizing: border-box;
    // background-image: url('searchicon.png');
    background-position: 14px 12px;
    background-repeat: no-repeat;
    font-size: 15px;
    padding: 14px 20px 12px 45px;
    border: none;
    // border-bottom: 1px solid #ddd;

    height: 3em;
    border: 0;
    background: #eee;
    border-radius: 2px;
    padding: 4px;
    padding-left: 1ex;
    padding-right: 1.5em;
    width: 100%;
    box-sizing: border-box;
    cursor: default;
  }

  #myInput:focus {
    outline: 2px solid #000;
    cursor: text;
  }

  .show {
    #myInput {
      z-index: 201;
      outline: 2px solid #000;
    }

    i {
      z-index: 201;
    }
  }

  .search {
    display:flex;
    align-items: center;
  }

  .dropdown {
    position: relative;
    display: inline-block;
    width: 100%; // todo only works if in table cell
    min-width: 100px;
    // z-index: 201;
    i {
      position: absolute;
      left: calc(100% - 1.2em);
    }

    i.fa-chevron-down {
      font-size: 11px;
      left: calc(100% - 1.5em);
    }
  }

  .dropdown-content {
    // display: none;
    position: absolute;
    background-color: #f6f6f6;
    min-width: 300px;
    border: 1px solid #ddd;
    z-index: 202;
    box-shadow: 0px 0px 9px 0px #bbb;
    margin-top: 2px;

    > div:first-child {
      padding: 1ex;
      font-style: italic;
      font-weight: 500;
    }

    > div:last-child {
      max-height: 400px;
      overflow-y: auto;
    }
  }

  .dropdown-content span {
    color: black;
    padding: 2px 1em;
    text-decoration: none;
    display: block;
    cursor: default;
    user-select: none;
  }

  .dropdown span:hover,
  .dropdown span.selected {
    background-color: #ddd;
  }
  .above {
    bottom: 48px;
  }
</style>
