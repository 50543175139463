<template>
  <div class="searchbox">
    <i class="fa fa-search"></i>
    <input type="text" :placeholder="placeholder" :value="modelValue" @input="$emit('update:modelValue', $event.target.value)" />
  </div>
</template>

<script>
  export default {
    name: "SearchBox",
    props: ["placeholder", "modelValue"],
    emits: ["update:modelValue"],
  }
</script>

<style scoped lang="scss">
  .searchbox {
    align-items: center; 
    display: flex;

    i {
      position: relative;
      left: 24px;
      font-size: 14px;
      color: #666;
    }
    input {
      padding: 0.5ex 1em;
      border: 1px solid rgba(68, 68, 68, 0.15);
      border-radius: 8px;
      height: 1.8em;
      padding-left: 2.4em;
      font-size: 14px;
    }
  }
</style>
