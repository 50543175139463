<template>
  <div v-if="loading" class="meter">
    <span><span :class="loading ? 'progress' : 'finished'"></span></span>
  </div>
</template>

<script>
  export default {
    name: 'LoadingBar',
    props: ['loading']
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
 .meter { 
    height: 5px;
    width: 100vw;
    overflow: hidden;
    position: fixed;
    top: var(--header-height);
    background-color: white;
    z-index: 101;
  }

  .meter span {
      display: block;
      height: 100%;
      background: #f3efe6;
  }

  .meter span.progress {
      background-color: #DE816B;
      animation: progressBar 1s ease-in-out;
      animation-fill-mode:both; 
  }

  .meter span.finished {
    background-color: #DE816B;
    animation: progressBarOut .8s ease-out;
    animation-fill-mode: both;
  }

  @keyframes progressBar {
    0% { width: 0; opacity: .4; }
    100% { width: 90%; opacity: 1; }
  }
  @keyframes progressBarOut {
    0% { width: 90%; opacity: 1; }
    70% { width: 100%; opacity: 1; }
    100% { width: 100%; opacity: 0; }
  }
</style>