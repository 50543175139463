<template>
<main v-if="data && review && review.title">
  <div class="breadcrumb">
    <PortalLink view="reviews">Access reviews</PortalLink>
    <!-- <span>{{review.title}}</span> -->
  </div>
  <HeadingSubtitle :text="review.title" icon="fa-solid fa-building-shield" />
  <hr />
  <!-- review details -->
  <dl class="listing">
    <dt>Review:</dt><dd><EditableProp v-model="review.title" @updated="updateDetails()" :readonly="!manageMode || review.status == Status.ARCHIVED" /></dd>
    <dt>Service:</dt><dd>{{service.name}}</dd>
    <dt>Description:</dt><dd><EditableProp v-model="review.description" @updated="updateDetails()" :readonly="!manageMode || review.status == Status.ARCHIVED" /></dd>
    <dt>Period:</dt><dd>
      <span><EditableProp v-model="review.period_start" type="date" @updated="updateDetails()" :readonly="!manageMode || review.status == Status.ARCHIVED" /></span>
       — 
      <span><EditableProp v-model="review.period_end" type="date" @updated="updateDetails()"  :readonly="!manageMode || review.status == Status.ARCHIVED"/></span>
    </dd>
    <dt>Review owner:</dt><dd>{{review.owner_name}}<br><span style="font-size:smaller;color:#a0a0a0">{{review.owner_email}}</span></dd>
    <template v-if="manageMode">
      <dt>Status:</dt>
      <dd>{{mapReviewStatus(review.status)}}
        <span v-if="review.status == Status.INIT || review.status == Status.VERIFYING" style="margin-left: 2em;">
          <svg width="24" height="24" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg"><path d="M12,4a8,8,0,0,1,7.89,6.7A1.53,1.53,0,0,0,21.38,12h0a1.5,1.5,0,0,0,1.48-1.75,11,11,0,0,0-21.72,0A1.5,1.5,0,0,0,2.62,12h0a1.53,1.53,0,0,0,1.49-1.3A8,8,0,0,1,12,4Z" class="spinner_aj0A"/></svg>
          <span style="margin-left: 1em; color:#333; font-size: small;">{{ processStatus }}</span>
        </span>
      </dd>
      <dt v-if="review.status > Status.VERIFYING && verificationSummary">Verification:</dt>
      <dd v-if="review.status > Status.VERIFYING && verificationSummary">{{verificationSummary.passed < verificationSummary.total ? "Failed" : "Passed"}} ({{verificationSummary.passed}}/{{verificationSummary.total}})</dd>
    </template>
  </dl>

  <!-- top tool bar -->
  <div class="top-filter">
    <div  class="advanced-filter" style="width: 100%">
      <div style="flex-grow: 2; min-width: 121px;">
        <button class="add" @click="openFilters()"><i class="fa-solid fa-filter" style="font-size:larger"></i> Filter</button>
        <button style="color: #333;" title="Clear filters" v-if="filters" class="subtle-accent" @click="filters = {}; loadData()"><i class="fa-solid fa-filter-circle-xmark"></i></button>
        <!-- <button class="remove" v-for="(value, filter) in filters" :key="filter" @click="delete filters[filter]; loadData()">{{filter}}: {{value}} <i style="margin-left: 1em;" class="fa-solid fa-x"></i></button> -->
        <!-- <span class="page-progress-info" v-if="accessData !== null">Page {{page + 1}} of {{pageCount + 1}}</span> -->
      </div>
      <div class="quick-filter">
        <ToggleSwitch v-if="manageMode" label="Hide submitted" v-model="filters.noSubmitted" @change="loadData()"/>
        <ToggleSwitch v-if="!manageMode" label="Pending only" v-model="filters.pendingOnly" @change="loadData()"/>
        <!-- <label v-if="manageMode" for="hideapproved">Hide submitted <input type="checkbox" name="hideapproved" v-model="filters.noSubmitted" @change="loadData()" /></label>
        <label v-if="!manageMode" for="hideapproved">Pending only <input type="checkbox" name="hideapproved" v-model="filters.pendingOnly" @change="loadData()" /></label> -->
        <br/>
        <!-- <label for="privilegedOnly">Privileged access only <input type="checkbox" name="privilegedOnly" v-model="filters.privilegedOnly" @change="loadData()" /> </label> -->
      </div>
      <div v-if="manageMode" class="actions" style="display:flex; justify-content: right; gap: 0.5ex;">
        <button v-if="review.has_report" class="btn-new outline" @click="downloadReport()"><i class="fa-solid fa-file-pdf"></i>Report</button>
        <button v-if="review.status > Status.INIT" class="btn-new outline" @click="downloadCsv()">Export CSV</button>
        <button v-if="isOwner && review.status == Status.READY" class="btn-new" @click="activateReview()">Set active</button>
        <button v-if="isOwner && (review.status == Status.SIGNED_OFF || review.status == Status.VERIFY_FAILED || review.status == Status.VERIFIED)" class="btn-new outline" @click="showVerfiy=true"><i class="fa-solid fa-upload"></i>Verify</button>
        <button v-if="isOwner && review.status == Status.ACTIVE" class="btn-new"  @click="signOffReview(true)"><i class="fa-solid fa-lock"></i>Sign off review</button>
        <button v-if="isOwner && review.status == Status.VERIFIED" class="btn-new" @click="showArchive=true">Archive</button>
      </div>
      <div v-if="!manageMode" class="actions" style="display:flex; justify-content: right; gap: 0.5ex;">
        <button class="btn-new" :disabled="Object.values(data).some(x=>x.approval_status == 0) || Object.values(data).every(x => x.locked)" @click="submitReviews(true)"><i class="fa-regular fa-handshake"></i>Submit review</button>
      </div>

      <div class="transparent-overlay" @click="showFilter=false" v-if="showFilter"></div>      
      <div class="new-filter" v-if="showFilter && filterOptions">
        <!-- <form @submit.prevent=""> -->
          <div class="" style="left: 40px; top: -12.5px;    width: 20px;    position: absolute;transform: translate(-50%, 0);">
            <svg viewBox="0 0 32 16" style="filter: drop-shadow(rgba(0, 0, 0, 0.09) 0px 0px 2px); fill: rgb(255, 255, 255);">
              <path d="M 16,0 L32,16 H0 Z" style="fill: rgb(210, 210, 210);"></path>
              <path d="M 16,1 L31,16 H1 Z"></path>
            </svg>
          </div>
          <section>
            <span>Name</span>
            <input class="subtle" v-model="filters.name" ref="namefilter" type="text" />
            <button class="clear subtle" @click="filters.name = ''"><i class="fa-solid fa-xmark"></i></button>
          </section>
          <section>
            <span>Job Title</span>
            <select class="subtle" v-model="filters.job">
              <option hidden disabled selected></option>
              <option v-for="j in filterOptions.jobs" :value="j.id" :key="j.id">{{j.title}}</option>
            </select>
            <button class="clear subtle" @click="filters.job = ''"><i class="fa-solid fa-xmark"></i></button>
          </section>
          <section>
            <span>Country</span>
            <select class="subtle" v-model="filters.country">
              <option hidden disabled selected></option>
              <option v-for="c in filterOptions.countries" :value="c.id" :key="c.id">{{c.title}}</option>
            </select>
            <button class="clear subtle" @click="filters.country = ''"><i class="fa-solid fa-xmark"></i></button>
          </section> 
          <section v-if="manageMode">
            <span>Reviewers</span>
            <select class="subtle" v-model="filters.reviewer">
              <option hidden disabled selected></option>
              <option v-for="r in filterOptions.reviewers" :value="r.reviewer_id" :key="r.reviewer_id">{{r.reviewer_name}}</option>
            </select>
            <button class="clear subtle" @click="filters.reviewer = ''"><i class="fa-solid fa-xmark"></i></button>
          </section>
          <section v-if="manageMode">
            <span>Status</span>
            <select class="subtle" v-model="filters.approval">
              <option hidden disabled selected></option>
              <option value="0">Pending</option>
              <option value="1">Approved</option>
              <option value="2">Revoked</option>
            </select>
            <button class="clear subtle" @click="filters.approval = ''"><i class="fa-solid fa-xmark"></i></button>
          </section>
          <section v-if="manageMode && review.status > Status.VERIFYING">
            <span>Verification</span>
            <select class="subtle" v-model="filters.verified">
              <option hidden disabled selected></option>
              <option value="null">Pending</option>
              <option value="true">Confirmed</option>
              <option value="false">Failed</option>
            </select>
            <button class="clear subtle" @click="filters.verified = ''"><i class="fa-solid fa-xmark"></i></button>
          </section>
          <section style="justify-content: right"> 
            <label v-if="manageMode" for="hideapproved">Hide submitted <input type="checkbox" name="hideapproved" v-model="filters.noSubmitted" /></label>
            <label v-if="!manageMode" for="hideapproved">Pending only <input type="checkbox" name="hideapproved" v-model="filters.pendingOnly" /></label>
          </section> 
          <section style="justify-content: right"> 
            <label for="privilegedOnly">Privileged access only <input type="checkbox" name="privilegedOnly" v-model="filters.privilegedOnly" /> </label>
          </section> 
          <section style="display: flex; gap: 1ex; justify-content: right; margin-top:1em">
            <button class="subtle" @click="showFilter=false; loadData()" style="border: 1px solid #ddd"><i class="fa-solid fa-arrows-rotate"></i> Apply</button>
            <button class="subtle" @click="filters={}; loadData()"><i class="fa-solid fa-filter-circle-xmark"></i> Clear</button>
          </section>
        <!-- </form> -->
      </div>
    </div>
  </div>

  <!-- data table -->
  <table class="data-table">
    <!-- data header -->
    <tr class="data-header">
      <th>ID</th>
      <th>User</th>
      <th>Job Title /<br/> Job Type</th>
      <th>Country /<br/> Company</th>
      <th>Role</th>
      <th>Scope</th>
      <th v-if="manageMode">Reviewer</th>
      <th class="right">Status</th>
      <th v-if="review.status > Status.READY && (isOwner || !manageMode)" class="actions">Actions</th>
      <th v-if="manageMode && review.status > Status.VERIFYING">Verification</th>
    </tr>

    <!-- data body -->
    <template v-for="access in accessData" :key="access[0].user_id">
    <tr class="data-row" :class="{'firstrow': acs.id == access[0].id, 'lastrow': acs.id == access[access.length - 1].id}" v-for="acs in access" :key="acs.id" :set="count=access.length">
      <template v-if="acs.id == access[0].id">
        <td class="stick row-id" :rowspan="count"><div class="stick">{{ acs.id }}</div></td>
        <td class="stick" :rowspan="count" :class="{'deactivated':acs.name.includes('[T]')}" v-if="acs.name">
          <div class="stick">
            <a v-if="acs.wd_profile" target="_blank" :href="acs.wd_profile">{{acs.name}}</a>
            <span v-else>{{acs.name}}</span>
            <br><span style="font-size:smaller;color:#a0a0a0">{{access[0].username}}</span>
          </div>
        </td>
        <td class="stick" :rowspan="count" v-else-if="acs.username"><div class="stick">{{acs.username}}</div></td>
        <td class="stick" :rowspan="count" v-else><div class="stick">{{acs.user_id}}</div></td>
        <td class="stick" :rowspan="count"><div class="stick">{{acs.job_title}}<span class="jobType" v-if="acs.job_type != 'NOT DEFINED'"><br>{{acs.job_type}}</span></div></td>
        <td class="stick" :rowspan="count"><div class="stick">{{acs.country}}<br/>{{acs.company}}</div></td>
      </template>
      <td v-if="acs.docurl"><a :href="acs.docurl" target="_blank">{{acs.role}}</a></td>
      <td v-else>{{acs.role}}</td>
      <td>{{acs.scope}}</td> 
      <td v-if="manageMode">
        <span class="onBehalf" v-if="acs.approval_by && acs.approval_by != acs.reviewer_id">{{acs.approver_first_name}} {{acs.approver_last_name}} on behalf of</span>
        <abbr :title="acs.reviewer_role">{{acs.reviewer_name}}</abbr>
      </td>
      <!-- status -->
      <td class="review-status" style="min-width: 11em">
        <span v-if="acs.ticket_grant" style="margin-left: 1ex" :title="'Ticket grant:\n' + acs.ticket_info"><i class="fa-solid fa-ticket"></i></span>&nbsp;
        
        <template v-if="manageMode">
          <template v-if="acs.birthright || acs.ticket_grant || acs.locked || acs.approval_by == permissions.user.id">
            <span class="approvalText" :title="approvalText(acs)" v-if="acs.approval_status == 1">Approved</span>
            <span class="revokedText" :title="approvalText(acs)" v-else-if="acs.approval_status == 2">Revoked</span>
            <span v-else>Pending</span>
          </template>
          <span v-else>Pending</span>
        </template>
        <template v-else-if="acs.birthright">
          <span class="approvalText" :title="approvalText(acs)" v-if="acs.approval_status == 1">Approved</span>
        </template>
        <template v-else>
          <span class="approvalText" :title="approvalText(acs)" v-if="acs.approval_status == 1">Approve</span>
          <span class="revokedText" :title="approvalText(acs)" v-else-if="acs.approval_status == 2">Revoke</span>
          <span v-else>Pending</span>
          <span v-if="acs.locked"> (submitted)</span>
        </template>
        
        <!-- single access actions -->
        <template v-if="!acs.birthright && review.status > Status.READY && (isOwner || !manageMode)">
          <template v-if="!acs.locked">
            <button :disabled="acs.approval_status == 1 || acs.worker_id == permissions.user.id" @click="approve(acs)" title="Approve role"><i class="fa-solid fa-check"></i></button>
            <button :disabled="acs.approval_status == 2 || acs.worker_id == permissions.user.id" @click="revoke(acs)" title="Revoke role"><i class="fa-regular fa-trash-can"></i></button>
          </template>
          <span class="locked-info" v-else-if="review.status == Status.ACTIVE">
            <i title="This review has alredy been submitted and cannot be changed anymore." class="fa-solid fa-lock"></i>
          </span>
        </template>
        <span v-else-if="acs.birthright" style="margin-left: 1ex"><a target="_blank" href="https://woltwide.atlassian.net/wiki/spaces/PEOP/pages/3186917497/Access+Birthright">(Birthright)</a></span>
      </td> 
      <!-- approve / revoke -->
      <td class="stick actions" :rowspan="count" v-if="acs.id == access[0].id && review.status > Status.READY && (isOwner || !manageMode)">
        <div class="stick" v-if="!access.every(acs => acs.birthright)">
          <button v-if="access.some(acs => acs.locked != true) && access[0].worker_id != permissions.user.id"
            :class="{ approve: true, greyButton: access.every(acs => acs.approval_status == 1 || acs.birthright)}"
            class="approve" @click="approveAll(access, access[0].user_id)"
            title="Approve all"><i class="fa-solid fa-check"></i></button>
          <button v-if="access.some(acs => acs.locked != true) && access[0].worker_id != permissions.user.id"
            :class="{ approve: true, greyButton: access.every(acs => acs.approval_status == 2 || acs.birthright)}"
            class="revoke" @click="revokeAll(access, access[0].user_id)"
            title="Revoke all"><i class="fa-regular fa-trash-can"></i></button>
        </div>
      </td>
      <!-- verification -->
      <td v-if="manageMode && review.status > Status.VERIFYING">
        <span style="font-size:smaller;" v-if="acs.verified === null">Pending</span>
        <span style="font-size:smaller;color:green" v-else-if="acs.verified === true">Confirmed</span>
        <span style="color:red; font-weight:bold" v-else-if="acs.verified === false">Failed</span>
        <br><span style="font-size:smaller"> {{formatDate(acs.verified_date)}}</span>
      </td>
    </tr>
    </template>
  </table>

  <!-- Popup dialogs -->
  <ModalDialog title="Confirm submission" v-if="showSubmit" @close="showSubmit=null">
    <p>You hereby confirm that all accesses are correct.</p>
    <p>This cannot be undone.</p>
    <p style="color:red" v-if="showSubmit.pending">There are {{showSubmit.pending}} role reviews pending. Sign off blocked.</p>
 
    <template v-slot:buttons>
      <button class="btn-new" :disabled="showSubmit.pending > 0" @click="submitReviews(false); showSubmit=null">Confirm & submit</button>
      <button class="btn-new outline" @click="showSubmit=null">Cancel</button>
    </template>
  </ModalDialog>

  <ModalDialog title="Confirm sign off" v-if="showSignOff" @close="showSignOff=null" :hideClose="this.$root.loading">
    <p>You are about to sign off this review. Nobody will be able to approve or revoke accesses anymore.</p>
    <p v-if="showSignOff.pending" style="font-weight:bold">There are {{showSignOff.pending}} role reviews pending. Sign off anyway?</p>
    <p>This action cannot be undone.</p>
    
    <template v-slot:buttons>      
      <button class="btn-new" @click="signOffReview(false)">Confirm sign off</button>
      <button class="btn-new outline" @click="showSignOff=null">Cancel</button>
    </template>
  </ModalDialog>
  
  <ModalDialog title="Archive review" v-if="showArchive" @close="showArchive=null">
    <p>Do you want to archive the review?</p>
    <p>This action cannot be undone.</p>
    
    <template v-slot:buttons>      
      <button class="btn-new" @click="archiveReview(false)">Archive</button>
      <button class="btn-new outline" @click="showArchive=null">Cancel</button>
    </template>
  </ModalDialog>

  <ModalDialog title="Verify review implementation" v-if="showVerfiy" @close="showVerfiy=null">
    <p>You are about to trigger a review verification. Make sure that all revoked accesses are removed from the target system before you continue.</p>
    <p>This action will take several minutes up until an hour, depending on the reviewed plattform.</p>

    <template v-slot:buttons>
      <button class="btn-new" :disabled="this.$root.loading" @click="verifyReview()">Start verification</button>
      <button class="btn-new outline" :disabled="this.$root.loading" @click="showVerfiy=null">Cancel</button>
    </template>
  </ModalDialog>
</main>

</template>

<script>
import {fetchFromApi, getDownloadUrl, postToApi, formatDateTime, formatDate, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js';
import {permissions} from '@/services/Permissions'
import ModalDialog from '@/components/ModalDialog.vue'
import EditableProp from '@/components/EditableProp.vue'
import PortalLink from '@/components/PortalLink.vue'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';
import ToggleSwitch from '@/components/objects/inputs/ToggleSwitch.vue';

const Status = {
  INIT: 0,
  READY: 1,
  ACTIVE: 2,
  SIGNED_OFF: 3,
  VERIFYING: 4,
  VERIFY_FAILED: 5,
  VERIFIED: 6,
  ARCHIVED: 7,
  ERROR: 11,
};


export default {
  name: 'AccessReviewView',
  components: {
    ModalDialog,
    EditableProp,
    HeadingSubtitle,
    PortalLink,
    ToggleSwitch
  },
  data() {
    return {
      reviewId: this.$route.params.id,
      service: {},
      review: {},
      isOwner: false,
      data: {},
      verificationSummary: {},
      accessData: null,
      page: 0,
      filterOptions: null,
      filters: {},
      showFilter: false,
      showSubmit: null,
      showSignOff: null,
      showVerfiy: null,
      showArchive: null,
      manageMode: this.$route.params.view == "manage",
      permissions: permissions,
      formatDateTime: formatDateTime,
      formatDate: formatDate,
      isLastPage: false,
      pageRequestInProgress: false,
      processStatus: "",
      intervalId: null,
      Status: Status,
    };
  },
  methods: {
    approvalText(acs){
      let locked = ""
      if(acs.locked) {
        locked = "(submitted) "
      }
      if(acs.approval_status == 1)
        return locked + 'Approved on ' + formatDateTime(acs.approval_date) + ' by ' + acs.approver_first_name + ' ' + acs.approver_last_name;
      return locked + 'Revoked on ' + formatDateTime(acs.approval_date) + ' by ' + acs.approver_first_name + ' ' + acs.approver_last_name;
    },
    async approveAll(access, userId) {
      await postToApi(this, `/api/review/${this.reviewId}/useraccess`, {"id": userId, "approval_status": 1, "approval_date": new Date(), "manage_mode": this.manageMode});
      this.updateAcs(access, 1);
    },
    async revokeAll(access, userId) {
      await postToApi(this, `/api/review/${this.reviewId}/useraccess`, {"id": userId, "approval_status": 2, "approval_date": new Date(), "manage_mode": this.manageMode});
      this.updateAcs(access, 2);
    },
    async approve(acs) {
      this.updateAcs([acs], 1);
      await postToApi(this, `/api/review/${this.reviewId}/access`, acs);
    },
    async revoke(acs) {
      this.updateAcs([acs], 2);
      await postToApi(this, `/api/review/${this.reviewId}/access`, acs);
    },
    updateAcs(acsList, approvalStatus) {
      let now = new Date();
      acsList.forEach(acs => {
        if (!acs.birthright) {
          acs.approval_status = approvalStatus;
          acs.approval_date = now;
          acs.approval_by = this.permissions.user.id;
          acs.approver_first_name = this.permissions.user.firstname;
          acs.approver_last_name = this.permissions.user.lastname;
        }
      });
    },
    async updateDetails(){
      let resp = await postToApi(this, `/api/review/${this.reviewId}`, this.review);
    },
    async openFilters(){
      if (!this.filterOptions) {
        const reviewId = this.$route.params.id;
        this.filterOptions = await fetchFromApi(this, `/api/review/${reviewId}/filters`, null, {"manage": this.manageMode});
      }
  
      this.showFilter = true;
    },
    async submitReviews(preview=true) {
      this.$root.loading = true;
      this.showSubmit = await postToApi(this, `/api/review/${this.reviewId}/submit`, {"preview": preview});
      this.$root.loading = false;

      if(!preview) {
        this.showSubmit = null;
        this.loadData();
      }
    },
    async signOffReview(preview=true) {
      this.$root.loading = true;
      this.showSignOff = await postToApi(this, `/api/review/${this.reviewId}/signoff`, {"preview": preview});
      this.$root.loading = false;

      if(!preview) {
        this.showSignOff = null;
        this.loadData();
      }
    },
    async activateReview() {
      this.$root.loading = true;
      await postToApi(this, `/api/review/${this.reviewId}/activate`);
      this.$root.loading = false;

      this.loadData();
      alert("Review is now open for reviewers.")
    },
    async archiveReview() {
      this.$root.loading = true;
      await postToApi(this, `/api/review/${this.reviewId}/archive`);
      this.$root.loading = false;

      this.showArchive = null;
      this.loadData();
    },
    async downloadReport() {
      const url = await getDownloadUrl(`/api/review/${this.reviewId}/report`)
      window.open(url, '_blank');
    },
    async downloadCsv() {
      const url = await getDownloadUrl(`/api/review/${this.reviewId}/csv`)
      window.open(url, '_blank');
    },
    async verifyReview() {
      await postToApi(this, `/api/review/${this.reviewId}/verification/start`);
      this.showVerfiy = null;
      this.loadData();
    },
    async loadData(appendPage = false) {
      if (appendPage) {
        if (this.isLastPage || this.pageRequestInProgress) {
          return;
        }
        this.pageRequestInProgress = true;
        this.page += 1;
      } else {
        this.page = 0;
        this.data = {}
        this.isLastPage = false;
      }

      const reviewId = this.$route.params.id;
      this.$root.loading = true;

      const params = {
        'filter': JSON.stringify(this.filters),
        'page': this.page
      };

      const mode = this.manageMode ? "manage" : "";
      let response = await fetchFromApi(this, `/api/review/${reviewId}/${mode}`, null, params);      
      this.$root.loading = false;



      if (!response.data.length) {
        this.isLastPage = true;
      }

      for(let acs of response.data) {
        this.data[acs.id] = acs;
      }

      this.review = response.review || this.review;
      this.isOwner = response.is_review_owner || response.is_review_admin; 
      this.service = response.service || this.service;
      this.verificationSummary = response.verificationSummary || this.verificationSummary;

      if (!appendPage && this.manageMode){
        await this.startProgressListener();
      }

      var groupBy = function(xs, key) {
        return xs.reduce(function(rv, x) {
          (rv[x[key]] = rv[x[key]] || []).push(x);
          return rv;
        }, {});
      };
      
      const groups = groupBy(Object.values(this.data), 'username');

      for(let key in groups){
        groups[key] = groups[key].sort((a, b) => ((a.role || "").localeCompare(b.role) * 10) + (a.scope || "").localeCompare(b.scope));
      }
      this.accessData = groups;
      this.pageRequestInProgress = false;
    },
    async startProgressListener() {
      if(this.review.status == Status.INIT || this.review.status == Status.VERIFYING) {
        const response = await fetchFromApi(this, `/api/review/${this.reviewId}/progress`);
        this.processStatus = response.text;
        // poll status every 30s
        this.intervalId = setInterval(async () => {
          if(this.review.status != Status.INIT && this.review.status != Status.VERIFYING) {
            this.processStatus = "";
            clearInterval(this.intervalId);
          } 

          const response = await fetchFromApi(this, `/api/review/${this.reviewId}/progress`);
          this.processStatus = response.text;
          this.review.status = response.status;
        }, 30000);
      }
    },
    mapReviewStatus(status){
      switch(status) {
        case 0: return "Initializing";
        case 1: return "Ready";
        case 2: return "Active";
        case 3: return "Signed-off";
        case 4: return "Verifying";
        case 5: return "Verification failed";
        case 6: return "Verified 100%";
        case 7: return "Archived";
        case 11: return "Error";
        default: return "Unknown";
      }
    }
  },

  async beforeMount() {
    this.loadData();
    this.$root.appClass["page-acr"] = true;
    registerInfiniteScoll(() => this.loadData(true));
  },
  async beforeUnmount() {
    this.$root.appClass["page-acr"] = false;
    unregisterInfiniteScoll();
    clearInterval(this.intervalId);
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss">
// top filter bar overrides
.page-acr #head-wide, .page-acr #head-compact {
  box-shadow: none!important;
}

</style>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

// top filter bar overrides
main {
  padding-top: 9.5rem;
}

.small {
  font-size: 12px;
}

.right {
  text-align: right;
}
.confirm-block {
  display: flex;
  justify-content: center;
  margin-top: 6rem;
}

.approve {
  background-color: green;
  margin: 0.5ex;
  border-radius: 10px;
}

.revoke {
  background-color: red;
  margin: 0.5ex;
}

.small-button {
  border-radius: 6px;
  height: 25px;
  font-size: smaller;
  margin: 1px;
  padding: 7px;
}

.greyButton {
  background-color: gray;
}

.approvalText {
  font-size: small;
  color: green;
}

.revokedText {
  font-size: small;
  color:red;
}

.review-status {
  font-size: small;
  min-width: 120px;
  text-align: right;

  button {
    font-size: 13px;
    background-color: transparent;
    color: black;
    margin: 0;
    padding: 6px 8px;
  }

  button:hover {
    background-color: $grayLight;
  }
  button:disabled {
    color: #e0e0e0;
  }
  button:disabled:hover {
    background-color: transparent
  }
}

.submit-review {
  font-family: 'Roboto';
  font-weight: 500;
  font-size: large;
}
tr {
  border: 0;
}
tr.firstrow {
  border-top: 1px solid #eee;
  td {
    padding-top: 1ex;
  }
}
tr.lastrow td {
    padding-bottom: 1ex;
}
tr.data-header {
    border-bottom: 1px solid;
}
.data-row td {
    padding: 1px 0.5em 1px 0;
}

td.deactivated, td.deactivated a {
  color: red //#f4cccc;
}

.data-table th {
  top: calc(var(--header-height) + 60px);
}

.data-table td:nth-child(2){
  max-width: 20em;
}

.is-pinned {
  background-color:red;
  text-overflow: ellipsis;
}

.jobType {
  font-size: smaller;
  color: #999;
}

.locked-info {
  margin-left: 1ex;
}

// spinner
.spinner_aj0A{transform-origin:center;animation:spinner_KYSC .75s infinite linear}
@keyframes spinner_KYSC{100%{transform:rotate(360deg)}}

span.onBehalf {
  display: block;
  font-size: 12px;
  padding: 0.5ex 0;
  color: #999;
}

td.stick {
  vertical-align: top;
}
div.stick {
  position: sticky;
  top: 184px;
}

.row-id {
  opacity: .75;
}

.top-filter .btn-new.outline {
  background-color: #f8f8f8;
}

// mobile view
@media only screen and (max-width: 550px) {
  .quick-filter{
    display: none;
  }
}
</style>
