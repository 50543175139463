<template>
  <div>
    <h4>Recent ticket automation activity</h4>
    <table class="data-table">
      <tr class="data-header">
        <th>Date</th>
        <th>Ticket</th>
        <th>User</th>
        <th>Plugin</th>
        <th>Level</th>
        <th>Text</th>
      </tr>
      <tr class="data-row" :class="row.level.toLowerCase()" v-for="row in ticketsLog" :key="row.id">
        <td>{{formatDateTime(row.timestamp)}}</td>
        <td><PortalLink view="ticket" :item="row.ticket_id">{{row.ticket_id}}</PortalLink></td>
        <td><PortalLink view="user" :item="row.user_id">{{row.user_id}}</PortalLink></td>
        <td>{{row.plugin}}</td>
        <td>{{row.level}}</td>
        <td>{{row.text}}</td>
      </tr>
      <tr class="data-row nodata" v-if="ticketsLog && ticketsLog.length == 0">
        <td colspan="5">No ticket data found!</td>
      </tr>
    </table>
  </div>
</template>

<script>
import {fetchFromApi, formatDateTime, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
export default {
  name: 'RecentTicketsLog',
  props: ['active'],
  watch: { 
    active: function(newVal, oldVal) { // watch it
      if(newVal && this.ticketsLog.length == 0) this.loadData();
    }
  },
  components: {
    PortalLink
  },
  data() {
    return {
      ticketsLog: [],
      formatDateTime: formatDateTime,
      page: 0,
    };
  },
  methods: {
    async loadData(page = 0) {
      if(this.$root.loading || !this.active) return; // one page load at a time
      this.page = page;

      const params = {'page': page}

      this.$root.loading = true;
      let response = await fetchFromApi(this, `/api/ticket.recentlog`, null, params);
      this.ticketsLog = this.ticketsLog.concat(response);
      this.$root.loading = false;
    }
  },
  async beforeMount() {
    this.loadData()

    registerInfiniteScoll(() => this.loadData(this.page + 1));
  },
  async beforeUnmount() {
    unregisterInfiniteScoll();
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
@import "~@/styles/_variables.scss";
.data-table {
  td:nth-child(1) { // date column
    min-width: 16ex; // no text wrapping
  }
}

.data-table td:nth-child(2), .data-table td:nth-child(3),
.data-table th:nth-child(2), .data-table th:nth-child(3){
  text-align: right;
}

.error {
  color: $itp-red100;
  font-weight: 500;
}

.warning{
  color: $itp-orange;
}

</style>