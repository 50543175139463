<template>
  <div v-if="birthrightsRulesets && birthrightsRulesets.length">
    <h5>Rule sets affecting the user</h5>

    <ul class="link-list">
      <li v-for="ruleset in birthrightsRulesets" :key="ruleset.id">
        <i class="fa-solid fa-fingerprint"></i> <router-link :to="'/birthrights/rulesets/' + ruleset.rule_set_id">{{ ruleset.title }}</router-link>
      </li>
    </ul>
  </div>
  <div v-else>
    <p>No rule sets affecting the user.</p>
  </div>
</template>

<script>
import {fetchFromApi} from '@/services/AppTools.js'
import {permissions} from '@/services/Permissions.js'

export default {
  name: 'UserBirthrights',
  data() {
    return {
      birthrightsRulesets: null,
    };
  },
  async beforeMount() {
    let userId = this.$route.params.id || "me";
    // load birthrights by user id
    if (permissions.isIT) {
      this.birthrightsRulesets = await fetchFromApi(this, '/api/birthrights/rulesets/search', null, { user_id: userId });
    }
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>
