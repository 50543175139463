<template>
<main v-if="app">
  <HeadingSubtitle :text="'Application: ' + app.name" icon="fa-solid fa-layer-group" />
  <hr />

  <tabs @changed="tabChanged">
    <tab name="Summary">
      <ul class="item-list compact">
        <li><b>Name:</b> {{app.name}}</li>
        <li><b>Id:</b> {{app.id}}</li>
        <li><b>Owner:</b> {{app.owner}}</li>
        <li><b>Type:</b> {{app.type}}</li>
      </ul>
      <span class="small">Last updated: {{formatDateTime(app.updated)}}</span>
    </tab>
    <!-- <tab name="Users" v-if="permissions.isIT">
      <AppUsers v-if="lazyLoadUsers" />
    </tab>
    <tab name="Costs" v-if="permissions.isIT">
      <AppCosts v-if="lazyLoadCosts" />
    </tab> -->
    <!-- <tab name="Requests">
        Requests tab content
    </tab>        
    <tab name="Onboardings">
        Onboardings tab content
    </tab> -->
  </tabs>
</main>

</template>

<script>
import AppUsers from '@/views/apps/AppUsers.vue';
import AppCosts from '@/views/apps/AppCosts.vue'
import {Tabs, Tab} from 'vue3-tabs-component';
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js';
import {permissions} from '@/services/Permissions.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';

export default {
  name: 'AppView',
  components: {
    // AppUsers,
    // AppCosts,
    Tabs, Tab,
    HeadingSubtitle,
  },
  data() {
    return {
      app: null,
      lazyLoadUsers: false,
      lazyLoadCosts: false,
      permissions: permissions,
      formatDateTime: formatDateTime
    };
  },
  methods: {
    tabChanged(selectedTab) {
      let tab = selectedTab.tab.name;

      // lazy load tab content when opened the first time
      this.lazyLoadUsers = this.lazyLoadUsers || tab == "Users";
      this.lazyLoadCosts = this.lazyLoadCosts || tab == "Costs";
    }
  },
  async beforeMount() {
    const appId = this.$route.params.id;

    this.$root.loading = true;
    this.app = await fetchFromApi(this, `/api/application/${appId}`);
    this.$root.loading = false;
  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
</style>