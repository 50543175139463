<template>
  <header v-if="plan" class="helsinki">
    <div> <!-- top row -->
      <!-- breadcrumb -->
      <menu class="breadcrumb">
        <ul><PortalLink view="hcplanning" class="bubble">Headcount Planning</PortalLink></ul>
        <ul><PortalLink view="hcplanning/admin" class="bubble">Admin</PortalLink></ul>
        <ul v-if="plan.parent_plan"><a :href="`/hcplanning/${plan.parent_plan}`" class="bubble plan-label">{{ plan.parent_plan_title }}</a></ul>
        
        <ul v-if="newSubplan"><span class="focus">Create subplan</span></ul>
        <ul v-else><a :href="`/hcplanning/${plan.id}`" class="bubble plan-label">{{ plan.title }} - Admin</a></ul>
      </menu>

      <!-- user notifications -->
      <div class="notifications">
        <div class="changes-saved-notice" ref="changesSavedNotice"><i class="fa-solid fa-check"></i>Changes saved!</div>
      </div>
    </div>

    <div> <!-- bottom row -->
      <!-- tab navigation -->
      <nav>
        <div class="active"><button class="btn-new subtle">Plan settings</button></div>
      </nav>
      
      <!-- view toolbar: plan -->
      <div class="toolbar">
        <button class="btn-new" @click="savePlan" :disabled="!allFieldsCorrect" v-if="activeTab === 'plans'"
          :title="!allFieldsCorrect ? 'Make sure every required field is filled in correctly.' : ''">
          <i class="fa-solid fa-save"></i>Save changes</button>
      </div>
    </div>
  </header>

  <main v-if="plan != null" class="helsinki">      
      <div class="plans-tab" v-if="activeTab === 'plans'">
        <dl v-if="plan" class="listing-table">
          <dt>ID</dt>
          <dd>{{ plan.id }}</dd>
          <!-- -- -->
          <dt v-if="plan.parent_plan">Parent Plan</dt>
          <dd v-if="plan.parent_plan">{{ plan.parent_plan_title }}</dd>
          <!-- -- -->
          <dt>Title</dt>
          <dd><input type="text" v-model="plan.title" placeholder="Plan title" required /></dd>
          <!-- -- -->
          <dt>SupOrg</dt>
          <dd>
            <FilterDropdown :value="plan.sup_orgs[0][0]" :text="plan.sup_orgs[0][1]" :items="supOrgs" 
              @select="plan.sup_orgs[0][0] = $event.id; plan.sup_orgs[0][1] = $event.value" />

            <!-- <input type="text" v-if="plan.extra_data" v-model="plan.extra_data.org_id" placeholder="SUP3.8" required
              @input="e => e.target.classList[/SUP[0-9]\.[0-9]/.test(e.target.value) ? 'remove' : 'add']('invalid-field')" />              -->
          </dd>
          <!-- -- -->
          <dt>SupOrg2</dt>
          <dd>
            <FilterDropdown :value="plan.sup_orgs[1][0]" :text="plan.sup_orgs[1][1]" :items="supOrgs" 
              @select="plan.sup_orgs[1][0] = $event.id; plan.sup_orgs[1][1] = $event.value" />
          </dd>
          <!-- -- -->
          <dt>SupOrg3</dt>
          <dd>
            <FilterDropdown :value="plan.sup_orgs[2][0]" :text="plan.sup_orgs[2][1]" :items="supOrgs" 
              @select="plan.sup_orgs[2][0] = $event.id; plan.sup_orgs[2][1] = $event.value" />
          </dd>
          <!-- -- -->
          <dt>Plan Owner</dt>
          <dd>
            <SelectUserInput :value="plan.owner" dataUrl="/api/hcp/0/hiringmanagers" dataCacheKey="hcp_hiringmanagers" class="ownerSelect"
              :text="(plan.owner_first_name ? `${plan.owner_first_name} ${plan.owner_last_name}` : '') || plan.owner" :class="{ 'invalid-field': !plan.owner }"
              @select="plan.owner = $event.id; [plan.owner_first_name, plan.owner_last_name] = $event.name.split(' ', 2); findInvalidFields()"
              @change="findInvalidFields"></SelectUserInput>
          </dd>
          <!-- -- -->
          <dt>Approvers</dt>
          <dd>
            <MultiUserInput @select="plan.agents = $event.map(obj => obj.id)" dataCacheKey="hcp_recruiters" :dataUrl="`/api/hcp/${planId}/recruiters`"
              :initial-users="plan.agents" />
          </dd>
          <!-- -- -->
          <!-- <dt>Approvers</dt>
          <dd>
            <MultiUserInput @select="plan.approvers = $event.map(obj => obj.id)" :dataUrl="`/api/hcp/${planId}/recruiters`" dataCacheKey="hcp_recruiters"
              :initial-users="plan.approvers" />
          </dd> -->
          <!-- -- -->
          <dt>Viewers</dt>
          <dd>
            <MultiUserInput @select="plan.viewers = $event.map(obj => obj.id)" :dataUrl="`/api/hcp/${planId}/recruiters`" dataCacheKey="hcp_recruiters"
              :initial-users="plan.viewers" />
          </dd>
          <!-- -- -->
          <dt>Cost Centers</dt>
          <dd>
            <FilterDropdown :value="plan.extra_data.cost_center" :text="plan.extra_data.cost_center_name" :items="costCenters" 
              @select="plan.extra_data.cost_center = $event.id; plan.extra_data.cost_center_name = $event.value" />
          </dd>
          <!-- -- -->
          <dt>Countries</dt>
          <dd>
            <select v-model="plan.extra_data.countries" v-if="woltCountries" multiple>
              <option v-for="country in woltCountries" :key="country.id" :value="country.value">{{ country.value }}</option>
            </select>
          </dd>
          <!-- -- -->
          <dt>Total Budget</dt>
          <dd><input type="number" v-model="plan.extra_data.total_funds" placeholder="10000" /></dd>
          <!-- -- -->
          <dt>Position Prefix</dt>
          <dd><input type="text" v-model="plan.extra_data.position_prefix" placeholder="Position prefix" /></dd>
          <!-- -- -->
          <dt>Actuals</dt>
          <dd><input type="text" v-model="plan.extra_data.actuals" placeholder="Actuals" /></dd>
          <!-- -- -->
          <dt>Plan Status</dt>
          <dd>
            <select v-model="plan.status">
              <option :value="0">INACTIVE</option>
              <option :value="10">ACTIVE</option>
            </select>
          </dd>
        </dl>

        <button class="btn-new" @click="savePlan"><i class="fa-solid fa-save"></i>Save changes</button>
      </div>
    </main>
  </template>
  
  <script>
    import SelectUserInput from "@/components/objects/inputs/SelectUserInput.vue";
    import MultiUserInput from "@/components/objects/inputs/MultiUserInput.vue";
    import FilterDropdown from "@/components/objects/inputs/FilterDropdown.vue";
    import PortalLink from '@/components/PortalLink.vue'
    import { fetchFromApi, putToApi, postToApi } from "@/services/AppTools";
    import { permissions } from "@/services/Permissions";
  
    const NEW_PLAN_ID = "<new plan>";

    export default {
      name: "HcAllPlansView",
  
      components: {
        SelectUserInput,
        MultiUserInput,
        FilterDropdown,
        PortalLink,
      },
  
      data() {
        return {
          plan: null,
          newSubplan: false,
          oldPlans: [],
          allPlans: [],
          woltCountries: [],
          costCenters: [],
          supOrgs: [],

          roles: [],

          allFieldsCorrect: true,
          activeTab: "plans",
  
          permissions,
        }
      },
  
      methods: {
        findInvalidFields() {
          // find fields with invalid-field CSS class, or unfulfilled required fields
          if (this.$el && this.$el.querySelectorAll) {
            let invalidFields = this.$el.querySelectorAll(".invalid-field, :required:placeholder-shown");
          
            this.allFieldsCorrect = invalidFields.length === 0;
          }
        },

        async savePlan() {
            let plan = this.plan;
            // check if any changes were made (compare to oldPlans)
            // let oldPlan = this.oldPlans.find(p => p.id == plan.id);
            // if (JSON.stringify(this.plan) === JSON.stringify(oldPlan)) {
            //     continue;
            // }

            if(plan.id === NEW_PLAN_ID) {
              const url = this.planId != 'new' ? `/api/hcp/${this.planId}/admin/subplan` : `/api/hcp/admin`;
              const new_plan = await postToApi(this, url, {
                  title: plan.title,
                  extra_data: plan.extra_data,
                  parent_plan: plan.parent_plan,
                  owner: plan.owner,
                  agents: plan.agents.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                  approvers: plan.approvers.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                  viewers: plan.viewers.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                  scope: plan.scope,
                  status: plan.status,
                  sup_orgs: plan.sup_orgs,
              });

              await this.$router.push(`/hcplanning/admin/${new_plan.id}`);
              this.$router.go();
            } else {
              await putToApi(this, `/api/hcp/admin/${plan.id}`, {
                title: plan.title,
                extra_data: plan.extra_data,
                parent_plan: plan.parent_plan,
                owner: plan.owner,
                agents: plan.agents.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                approvers: plan.approvers.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                viewers: plan.viewers.map(x => isNaN(x) ? x[0] : x), // x is id or id/name tuple
                scope: plan.scope,
                status: plan.status,
                sup_orgs: plan.sup_orgs,
              });
            }
            // // if changes were made, put them to the api
            // if (this.plan.id === undefined) {
            //     continue; // new plan
            // }



          // separate new plans from old plans
          // let newPlans = this.allPlans.filter(p => !this.oldPlans.find(op => op.id == p.id));

          // for (let plan of newPlans) {
         
          // }

          // this.fetchPlans();

          this.$refs.changesSavedNotice.animate([
            { opacity: 0, transform: "translateX(2em)" },
            { opacity: 1, transform: "translateX(0)" },
          ], {
            duration: 500,
            easing: "ease-out",
            fill: "forwards",
          });

          setTimeout(() => {
            if (!this.$refs.changesSavedNotice) return;

            this.$refs.changesSavedNotice.animate([
              { opacity: 1 },
              { opacity: 0 },
            ], {
              duration: 125,
              easing: "ease-in",
              fill: "forwards",
            });
          }, 2000);

        },
      },
  
      async beforeMount() {
        this.newSubplan = this.$route.params.view == "new-subplan";
        this.$root.appClass["page-helsinki"] = true;
        this.planId = this.$route.params.id;
        this.newPlan = this.planId == "new"
        if(this.newPlan) {
          this.plan = {
            id: NEW_PLAN_ID,
            title: "New Plan",
            extra_data: {},
            parent_plan: null,
            parent_plan_title: null,
            owner: null,
            sup_orgs: [[null, null], [null, null], [null, null]],
          };
        } else if(this.newSubplan) {
          const parentPlan = await fetchFromApi(this, `/api/hcp/admin/${this.planId}`)
          this.plan = {
            id: NEW_PLAN_ID,
            title: "New Plan",
            extra_data: {},
            parent_plan: parentPlan.id,
            parent_plan_title: parentPlan.title,
            owner: null,
            sup_orgs: [[null, null], [null, null], [null, null]],
          };
        } else {
          this.plan = await fetchFromApi(this, `/api/hcp/admin/${this.planId}`)
          this.plan.extra_data = this.plan.extra_data ? JSON.parse(this.plan.extra_data) : {};
          this.plan.sup_orgs.push([null, null]); // make sure there are at least  empty suporgs so the UI doesn't break
          this.plan.sup_orgs.push([null, null]);
          this.plan.sup_orgs.push([null, null]);
        }
        const editValues = await fetchFromApi("GET", `/api/hcp/${this.planId}/editvalues`, null, 
          {"cost_centers": true, "sup_orgs": true, "countries": true}
        );
        this.costCenters = editValues.cost_centers;
        this.supOrgs = editValues.sup_orgs;
        this.woltCountries = editValues.countries;
        this.oldPlans = JSON.parse(JSON.stringify(this.plan));

        // block refresh if changes were made
        // window.onbeforeunload = () => {
        //   if (JSON.stringify(this.allPlans) !== JSON.stringify(this.oldPlans)) {
        //     return true;
        //   }
        // }

        // unregister onbeforeunload when leaving the page
        this.$router.beforeEach((to, from, next) => {
          window.onbeforeunload = null;
          next();
        });
      },
      async beforeUnmount() {
        this.$root.appClass["page-helsinki"] = false;
      },
    }
  </script>
  
  <style scoped lang="scss">
  .data-table {
    margin-top: 0ex;

    tr {
      transition: all .2s ease-in-out;
    }

    tr.closed {
      color: #999;
    }

    .child-plan {
      background: #f6f6f6;
    }

    th {
      padding-top: 1.5em;
      height: 2.2em;
    }
  }
    
  .warn-info, .changes-saved-notice {
    background: var(--theme-accent);
    padding: 1.05em;
    margin-left: 1em;
    border-radius: 5px;
    margin-bottom: 1em;
    font-size: .8rem;
    font-weight: 600;
    color: #333;
    display: flex;
    align-items: center;
    width: auto;

    p {
      margin: 0;
    }

    i {
      margin-right: 1em;
    }
  }

  .changes-saved-notice {
    background: #4caf50;
    color: #fff;

    transition: all ease .2s;
    opacity: 0;
  }
  
  td input, td select, .listing-table input, .listing-table select {
    height: 3em;
    padding: 0 .5em;
    border: 1px solid #ddd;
    width: 500px;
  }

  .listing-table {
    dd, dt {
      padding-bottom: 1.5ex;
      padding-top: 1.5ex;
    }
  }

  .listing-table .ownerSelect, .listing-table .dropdown {
    width: 500px;
  }
  .listing-table select[multiple] {
    height: 7em;
  }
  td select {
    height: 3.5em; /* padding doesn't work with select */
  }

  .invalid-field, :required:placeholder-shown {
    background: #ffdddd;
    border: 1px solid #ff0000;
  }

  button:disabled {
    cursor: not-allowed;
  }

  button:disabled:focus {
    opacity: 1;
    transform: unset;
  }

  .ownerSelect {
    min-width: 200px;
  }
  </style>
