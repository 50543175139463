<template>
  <FilterDropdown :value="value" :text="text" custom-chevron="fa-solid fa-user"
      @select="$emit('select', $event)" ref="filterDropdown"
      v-slot="slotProps" :customFilter="searchPeople">
    
    <div class="title">{{ slotProps.item.name }}</div>
    <div class="sub">{{slotProps.item.details}}</div>
  </FilterDropdown>
</template>

<script>
  import {fetchFromApi} from '@/services/AppTools.js'
  import FilterDropdown from "@/components/objects/inputs/FilterDropdown.vue";

  export default {
    name: "SelectUserInput",
    props: ["value", "text", "dataUrl", "dataCacheKey"],
    emits: ["select"],
    components: { FilterDropdown },
    methods: {
      reset() {
        this.$refs.filterDropdown.reset();
      },
      async searchPeople(filterValue) {
        // window.allPeople will be initialized in people search
        let allpeople = window["__cache__" + this.dataCacheKey];
        if (!allpeople) {
          allpeople =  JSON.parse(window.sessionStorage.getItem(this.dataCacheKey))
        }
        if (!allpeople) {
          if(this.loadingData) {
            // load data has alredy been triggered
            return;
          }
          this.loadingData = true
          allpeople = await this.loadData();
          window["__cache__" + this.dataCacheKey] = allpeople;
          window.sessionStorage.setItem(this.dataCacheKey, JSON.stringify(allpeople));
          this.loadingData = false
        }

        let allFound = null;
        let query = filterValue.toLowerCase();

        if (query.includes("@")) {
          // filter by email
          query = query.trim();
          allFound = allpeople.filter(entry => {
            return query == entry.email.toLowerCase();
          });
        }
        else if(!isNaN(query)) {
          // filter by employee id
          let id = query.trim()
          allFound = allpeople.filter(entry => {
            return id == entry.id;
          });
        }
        else {
          // filter by name
          let queryParts = query.toLowerCase().split(" ");

          allFound = allpeople.filter(entry => {
            let nameparts = entry.name.toLowerCase().split(" ");
            for(let part of queryParts){
              if(!nameparts.some(np => np.startsWith(part))) {
                return false;
              }
            }
            return true;
          });
        }

        let slice = allFound.slice(0, 15);
        for(let entry of slice) {
          entry.value = entry.name;
        }
        return slice;
      },
      async loadData() {
        return await fetchFromApi(this, this.dataUrl, data => data);
      }
    },
  };
</script>
  
<style lang="scss" scoped>
  .sub {
    margin-top: 1px;
    color:#716E7B;
    font-size:11px;
  }
</style>
