<template>
  <nav class="appnav" :class="compact ? 'overlay-mode' : 'side-mode'">
    <div @click="$emit('click')" class="items">
      <PortalLink view="" class="nav-tooltip" data-text="Home"><i class="fa-solid fa-house"></i></PortalLink>
      <PortalLink v-if="permissions.hasAutomationHealthRead" view="automation" class="nav-tooltip" :class="current('/automation')" data-text="Automation Health"><i class="fa-solid fa-heart-pulse"></i></PortalLink>
      <PortalLink v-if="permissions.hasUsersRead" view="user" class="nav-tooltip" :class="current('/user')" data-text="Onboardings"><i class="fa-solid fa-user"></i></PortalLink>
      <PortalLink v-if="permissions.hasTicketsRead" view="ticket" class="nav-tooltip" :class="current('/ticket')" data-text="Ticket automation"><i class="fa-solid fa-bolt"></i></PortalLink>
      <div v-if="permissions.isIT" class="separator"></div>
      <PortalLink v-if="permissions.isIT" view="birthrights/rulesets" class="nav-tooltip" :class="current('/birthrights')" data-text="Birthrights"><i class="fa-solid fa-baby"></i></PortalLink>
      <PortalLink v-if="permissions.isIT" view="onboarding#onboard" class="nav-tooltip" :class="current('/onboarding')" data-text="External vendor onboarding"><i class="fa-solid fa-person-walking-arrow-right"></i></PortalLink>
      <PortalLink v-if="permissions.isIT" view="infopanels" class="nav-tooltip" :class="current('/infopanels')" data-text="Infopanels (Wolt Market)"><i class="fa-solid fa-display"></i><i class="icon-infopan fa-solid fa-circle-info"></i></PortalLink>
      <div class="separator"></div>
      <PortalLink view="reviews" class="nav-tooltip" :class="current('/reviews')" data-text="Access reviews"><i class="fa-solid fa-key"></i><i class="icon-acr fa-solid fa-check"></i></PortalLink>
      <PortalLink view="hcplanning" class="nav-tooltip" :class="current('/hcplanning')" data-text="Headcount planning"><i class="fa-solid fa-people-arrows"></i></PortalLink>
    </div>
  </nav>
</template>

<script>
import PortalLink from '@/components/PortalLink.vue';
import {permissions} from '@/services/Permissions'

export default {
  name: "AppMenu",
  props: ["compact"],
  components: {
    PortalLink
  },
  emits: ['click'],
  data() {
    return {
      permissions: permissions,
    };
  },
  methods: {
    current(view) {
      return this.$route.path.startsWith(view) ? "current" : null;
    }
  }
};
</script>

<style lang="scss" scoped>

.appnav.side-mode {
  position: fixed;
  top: 0;
  left: 0;
  z-index: 100;
  height: 100%;
  width: 75px;

  margin-top: 61px;
  background-color: #fafafa;
  border-right: 1px solid #f6f6f6;

  .separator {
    border-top: 1px solid rgb(209, 213, 219);
    width: 51px;
    margin: 0 1em;
    box-sizing: border-box;
  }

  .items {
    padding-top: 1ex;
    // background-color: rgb(243, 244, 246);
    display: flex;
    flex-direction: column;
    gap: 10px;
    align-items: center;
  }

  a {
    transition:all 0.3s ease;
    font-size: 20px;
    padding: 1ex;
    border: 1px transparent;
    box-sizing: border-box;
    text-align: center;
    width: 50px;
    height: 50px;
    color: rgb(55, 65, 81);
    border-radius: 5px;
    line-height: 1.5em;

    &:hover, &.current {
      background-color: #fafafa;
      filter: brightness(0.95);
    }
  }

  .icon-acr {
    scale: 0.7; 
    translate: 10px -20px; 
    position: relative;
  }

  .icon-infopan {
    scale: 0.7;
    translate: 10px -20px;
    position: relative;
  }

  .nav-tooltip {
    position:relative; /* making the .tooltip span a container for the tooltip text */

    &:before {
      content: attr(data-text); /* here's the magic */
      position:absolute;

      /* vertically center */
      top:50%;
      transform:translateY(-50%);
      
      /* move to right */
      left:100%;
      margin-left:10px; /* and add a small left margin */
      
      /* basic styles */
      width:200px;
      padding: 10px 1em;
      border-radius:8px;
      background:#232830;
      color: #fff;
      text-align:center;

      display:none; /* hide by default */

      font-size: 15px;
      font-weight: bold;
      width: auto;
      text-wrap: nowrap;
    }
    
    &:after {
      content: "";
      position:absolute;
      
      /* position tooltip correctly */
      left:100%;
      margin-left:-6px;
    
      /* vertically center */
      top:50%;
      transform:translateY(-50%);
    
      /* the arrow */
      border:10px solid #000;
      border-color: transparent #232830 transparent transparent;

      display:none;
    }

    &:hover:before, &:hover:after {
      display:block;
    }
  }
}

.appnav.overlay-mode {
  width: 100%;
  position: relative;
  display: inline;

  .items {
    display: flex;
    flex-direction: column;
  }

  a {
    color: black;
    padding: 1.4ex;
    font-size: 20px;
    text-decoration: none;
    transition: background-color .3s ease;

    transform: translateX(-100px);
    opacity: 0;
    animation: menu-entry-pop-up .3s ease 1 forwards;

    &:hover, &.current {
      background-color: rgba(32, 32, 32, 0.1);
    }

    i {
      width: 2em;
    }
  }
  a::after {
    content: attr(data-text); /* read content from element attribue */
    // content: "attr(data-text); /* read content from element attribue */";
    color: #000;
    position: relative;
    font-weight: 300;
  }

  .icon-acr {
    scale: 0.7;
    translate: -30px 10px;
    position: absolute;
  }

  .icon-infopan {
    scale: 0.7;
    translate: -30px 10px;
    position: absolute;
  }

  @for $i from 1 through 20 {
    a:nth-child(#{$i}) {
      animation-delay: $i * 0.03s;
    }
  }
}

@keyframes menu-entry-pop-up {
  0% {
    transform: translateX(-100px);
    opacity: 0;
  }
  100% {
    transform: translateX(0);
    opacity: 1;
  }
}
</style>