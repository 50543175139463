<template>
  <main v-if="onboardings">
    <HeadingSubtitle text="Current on-boardings" icon="fa-solid fa-ticket" />
    <hr />
    <div class="toolbar" style="display: flex; justify-content: right;">
      <span style="font-size: 14px; font-weight: 500; display: flex; align-items: center;">
        <ToggleSwitch v-model="delayedOnly" label="Show only delayed" />
      </span>
    </div>
    <table class="data-table">
      <thead>
      <tr>
        <th>User</th>
        <th>Country</th>
        <th>Start date</th>
        <th>Onboarding state</th>
        <th>Status</th>
      </tr>
      </thead>
      <template v-for="worker in onboardings" :key="worker.id">
        <tr :set="status = getStatusClass(worker)" class="data-row" :class="status" v-if="!delayedOnly || getStatusClass(worker) == 'delayed'">
          <td><PortalLink view="user" :item="worker.id">{{worker.first_name}} {{worker.last_name}} ({{worker.id}})</PortalLink></td>
          <td>{{worker.country.replaceAll("\"", "")}}</td>
          <td>{{formatDate(worker.start_date)}}</td>
          <td>{{formatState(worker.onboarding_state)}}</td>
          <td>{{formatStatus(worker)}}</td>
        </tr>
      </template>
    </table>
  </main>
</template>

<script>
import {fetchFromApi, formatDate, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';
import PortalLink from '@/components/PortalLink.vue';
import ToggleSwitch from '@/components/objects/inputs/ToggleSwitch.vue';

export default {
  name: 'RecentTickets',
  components: {
    PortalLink,
    HeadingSubtitle,
    ToggleSwitch
  },
  data() {
    return {
      onboardings: [],
      cursor: null,
      delayedOnly: Boolean(Number(localStorage.getItem("onboardings_overview_delayedOnly") || 0)),
      formatDate: formatDate,
      dayjs: require('dayjs'), // import date library,
    };
  },
  methods: {
    formatState(state){
      if(state == 10) return "preboarding";
      if(state == 11) return "preboarding complete";
      if(state == 20) return "onboarding started";
      if(state == 21) return "onboarding complete";
      return state;
    },
    formatStatus(worker){
      let startDate = this.dayjs(worker.start_date);
      if(worker.onboarding_state < 21 && startDate < this.dayjs()) return "Delayed";
      if(worker.onboarding_state < 21) return "Pending"
      return "Ok";
    },
    getStatusClass(worker){
      let startDate = this.dayjs(worker.start_date);
      if(worker.onboarding_state < 21 && startDate < this.dayjs()) return "delayed";
      if(worker.onboarding_state < 21) return "pending"
      return "ok";
    },
    async loadData(cursor = "") {
      if (cursor === null) {
        return;
      }

      const params = {'cursor': cursor}

      this.$root.loading = true;
      let onboardings = await fetchFromApi(this, '/api/user.onboardings', null, params);
      this.onboardings = this.onboardings.concat(onboardings.data);
      this.cursor = onboardings.next_cursor
      this.$root.loading = false;
    },
  },
  async beforeMount() {
    await this.loadData();

    registerInfiniteScoll(() => { if(!this.$root.loading) { this.loadData(this.cursor) } });
  },
  async beforeUnmount() {
    unregisterInfiniteScoll();
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ribbon {
  padding: 0.2rem 0.5rem;
  margin: 0 0.2rem;
  font-size: 100%;
  white-space: nowrap;
  // background: #F1F1F1;
  // border: 1px solid #E1E1E1;
  border-radius: 18px;
  font-family: "Consolas", monospace;
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
}

.r-ok {
  // background: #d6f5d6;
  // border: 1px solid #84e184;
  color: green;
}
.r-error {
  // background: #ffb3b3;
  // border: 1px solid #ff4d4d;
  color: red;
}
.r-warn {
  background: #fff0b3;
  border: 1px solid #ffdb4d;
}

table.compact {
  width: 100%;
  border-spacing: 0;
  td {
    border: 0;
  }
}
.data-row.ok {
  td:nth-child(5){
    color: green;
  }
}

.data-row.delayed {
  color: red;
}
</style>
