import { createRouter, createWebHistory } from 'vue-router'
import Home from '../views/Home.vue'
import LoginPage from '../views/LoginPage.vue'
import AppView from '../views/apps/AppView.vue'
import AllAppsView from '../views/apps/AllAppsView.vue'
import UserView from '../views/users/UserView.vue'
import UsersView from '../views/users/UsersView.vue'
import CrossCompanyLinkView from '../views/users/CrossCompanyLinkView.vue'
import OnboardingsOverview from "../views/users/OnboardingsOverview.vue"
import TicketView from '../views/tickets/TicketView.vue'
import TicketsView from '../views/tickets/TicketsView.vue'
import AllAutomationsView from '../views/AllAutomationsView.vue'
import AccessReviewView from '../views/accessreviews/AccessReviewView.vue'
import ReviewsView from '../views/accessreviews/ReviewsView.vue'
import PageNotFound404 from '../views/global/404.vue'
import NetworkInfoView from "../views/network/NetworkInfoView.vue"
// hc planning
import HcpHome from '../views/hcplanning/Home.vue'
import PlanHistoryView from "../views/hcplanning/PlanHistoryView.vue"
import PlansAdminView from "../views/hcplanning/AdminView.vue"
import PlanAdminView from "../views/hcplanning/PlanAdminView.vue"
import PlanView from '../views/hcplanning/PlanView.vue'

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user',
    name: 'UsersView',
    component: UsersView,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/user/onboardings',
    name: 'OnboardingsOverview',
    component: OnboardingsOverview,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/user/:id/:tab?',
    name: 'UserView',
    component: UserView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/doordashlink',
    name: 'CrossCompanyLinkView',
    component: CrossCompanyLinkView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/application',
    name: 'AllAppsView',
    component: AllAppsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/application/:id/:tab?',
    name: 'AppView',
    component: AppView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/automation',
    name: 'AllAutomationsView',
    component: AllAutomationsView,
    meta: {
      requiresAuth: true
    }
  },  
  {
    path: '/networks',
    name: 'NetworkInfoView',
    component: NetworkInfoView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ticket',
    name: 'TicketsView',
    component: TicketsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/ticket/:id/:tab?',
    name: 'TicketView',
    component: TicketView,
    meta: {
      requiresAuth: true
    }
  },
  // access reviews
  {
    path: '/reviews',
    name: 'ReviewsView',
    component: ReviewsView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/reviews/:id/:view(manage)?', // allow optional /manage at the end of the route
    name: 'AccessReviewView',
    component: AccessReviewView,
    meta: {
      requiresAuth: true
    }
  }, 
  // headcount planning
  {
    path: '/hcplanning',
    name: 'AllPlansView',
    component: HcpHome,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hcplanning/:id/:view(plan|current|approvals|subplans)?', // allow optional /approvals at the end of the route
    name: 'PlanView',
    component: PlanView,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/hcplanning/:planId/history/:positionId',
    name: 'PlanPositionHistoryView',
    component: PlanHistoryView,
    meta: {
      requiresAuth: true,
    }
  },
  {
    path: '/hcplanning/admin',
    name: 'PlansAdminView',
    component: PlansAdminView,
    meta: {
      requiresAuth: true,
    }
  },  
  {
    path: '/hcplanning/admin/:id/:view(new-subplan)?',
    name: 'PlanAdminView',
    component: PlanAdminView,
    meta: {
      requiresAuth: true,
    }
  },
  
  // other
  {
    path: '/stylegallery',
    name: 'StyleGalleryView',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/other/StyleGalleryView.vue'),
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/login',
    name: 'Login',
    component: LoginPage,
    meta: {
      requiresAuth: false
    }
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    //component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  },
  {
    path: "/:pathMatch(.*)*", 
    component: PageNotFound404
  },
  {
    path: "/onboarding",
    component: () => import("../views/onboarding/OnboardingFrameRwView.vue"),
  },
  {
    path: "/onboarding/worker/new",
    component: () => import("../views/onboarding/tabs/NewWorkerView.vue"),
  },
  {
    path: "/onboarding/vendor/new",
    component: () => import("../views/onboarding/tabs/NewVendorView.vue"),
  },
  {
    path: "/onboarding/role/new",
    component: () => import("../views/onboarding/tabs/NewRoleView.vue"),
  },
  {
    path: "/onboarding/worker/:id",
    component: () => import("../views/onboarding/tabs/WorkerView.vue"),
  },
  {
    path: "/onboarding/vendor/:id",
    component: () => import("../views/onboarding/tabs/VendorView.vue"),
  },
  {
    path: "/onboarding/role/:id",
    component: () => import("../views/onboarding/tabs/RoleView.vue"),
  },
  {
    path: "/birthrights/rulesets",
    component: () => import("../views/birthrights/BirthrightsRuleSetList.vue"),
  },
  {
    path: "/birthrights/rulesets/add",
    component: () => import("../views/birthrights/BirthrightsNewRuleSet.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId",
    component: () => import("../views/birthrights/BirthrightsRuleSetRw.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId/rejected",
    component: () => import("../views/birthrights/BirthrightsRuleSetRejected.vue"),
  },
  {
    name: "BirthrightsRuleManageRw",
    path: "/birthrights/rulesets/:rulesetId/rule/:ruleVersionId/manage",
    component: () => import("../views/birthrights/BirthrightsRuleManageRw.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId/rule/:ruleVersionId/history",
    component: () => import("../views/birthrights/BirthrightsRuleHistory.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId/approvers",
    component: () => import("../views/birthrights/BirthrightsRuleSetApprovers.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId/approvers/new",
    component: () => import("../views/birthrights/BirthrightsRuleSetApproversNew.vue"),
  },
  {
    path: "/birthrights/rulesets/:rulesetId/approvers/:approverId",
    component: () => import("../views/birthrights/BirthrightsRuleSetApproversNew.vue"),
  },
  {
    path: "/infopanels",
    component: () => import("../views/infopanels/InfoPanelsView.vue"),
  },
  {
    path: "/infopanels/new",
    component: () => import("../views/infopanels/NewInfoPanelView.vue"),
  },
  {
    path: "/infopanels/:screenID/edit",
    component: () => import("../views/infopanels/NewInfoPanelView.vue"),
  },
  {
    path: "/infopanels/:screenID",
    component: () => import("../views/infopanels/InfoPanelView.vue"),
  }
]

export default createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})