<template>
<main>
  <HeadingSubtitle text="Access reviews" icon="fa-solid fa-building-shield" />
  <hr />

  <div class="toolbar" style="display: flex; justify-content: space-between; flex-direction:  row-reverse">
    <span style="font-size: 14px; font-weight: 500; display: flex; align-items: center;">
      <ToggleSwitch v-model="showClosed" label="Show completed reviews" />
    </span>
    <button v-if="permissions.hasAccessReviewAdmin" class="btn-new outline" @click="openNewDialog()">Start new review</button>
  </div>
  
  <h4 class="subtitle">My reviews</h4>
  <table class="data-table">
    <thead>
      <tr class="data-header">
        <th colspan="2">Review</th>
        <th>Service</th>
        <th>Status</th>
        <th colspan="2">Period</th>
      </tr>
    </thead>
    <!-- My reviews -->
    <tbody>
      <template v-for="row in openReviews" :key="row.review_id">
        <tr class="data-row" v-if="showClosed || !row.locked" :class="{closed: row.locked}">
          <td colspan="2"><PortalLink view="reviews" :item="`${row.review_id}`">{{row.title}}</PortalLink></td>
          <td>{{row.service_name}}</td>
          <td>{{row.locked ? "Completed" : "Pending"}}</td>
          <td colspan="2">{{row.period_start}} - {{row.period_end}}</td>
        </tr>
      </template>
      <tr class="data-row nodata" v-if="openReviews && openReviews.length == 0">
        <td colspan="4">Nothing here!</td>
      </tr>
    </tbody>
    <!-- View as service owner (active) -->
    <tbody v-if="ownedReviews && ownedReviews.length > 0">
      <tr><td colspan="4"><h4 class="subtitle">Manage reviews</h4></td></tr>
      <tr class="data-header">
        <th>Review</th>
        <th>Review owner</th>
        <th>Service</th>
        <th>Status</th>
        <th>Period</th>
      </tr>
      <template v-for="row in ownedReviews" :key="row.review_id">
        <tr class="data-row" v-if="showClosed || row.status != 7" :class="{closed: row.status == 7}">
          <td><PortalLink view="reviews" :item="`${row.review_id}/manage`">{{row.title}}</PortalLink></td>
          <td>{{row.review_owner}}</td>
          <td>{{row.service_name}}</td>
          <td>{{mapStatus(row.status)}} <span style="font-size:smaller; color: #999" v-if="row.status == 2">({{ Math.round((row.submitted || 0) / ((row.pending || 0) + (row.submitted || 0)) * 100) }}%)</span></td>
          <td>{{row.quarter}} <span style="margin-left: 1em;font-size:smaller">{{row.period_start}} - {{row.period_end}}</span></td>
        </tr>
      </template>
      <tr class="data-row nodata" v-if="ownedReviews && ownedReviews.length == 0">
        <td colspan="4">No previous reviews found!</td>
      </tr>
    </tbody>
  </table>

  <!-- Popup dialogs -->
  <ModalDialog title="Start new review" v-if="showNewDialog" @close="showNewDialog=null">
    <p>Select the service. It may take up to 1h until the review is ready.</p>

    <div>
      <label for="quarter">Quarter: </label>
      <select name="quarter" v-model="newReviewParams.quarter">
        <option hidden disabled selected></option>
        <option value="Q1" key="Q1">Q1</option>
        <option value="Q2" key="Q2">Q2</option>
        <option value="Q3" key="Q3">Q3</option>
        <option value="Q4" key="Q4">Q4</option>
      </select>
    </div>
    <div>  
      <label for="service">Service: </label>
      <select name="service" v-model="newReviewParams.service">
        <option hidden disabled selected></option>
        <option v-for="app in showNewDialog" :value="app.service_id+':'+app.plugin" :key="app.service_id+':'+app.plugin">
          {{app.name}} ({{app.plugin}})
        </option>
      </select>
    </div>
    
    <template v-slot:buttons>
      <button class="btn-new" @click="startNewReview(newReviewParams.service, newReviewParams.quarter)">Start new review</button>
      <button class="btn-new outline" @click="showNewDialog=null">Cancel</button>
    </template>
  </ModalDialog>
</main>
</template>

<script>
import {fetchFromApi, formatDateTime, postToApi} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';
import ToggleSwitch from '@/components/objects/inputs/ToggleSwitch.vue';
import {permissions} from '@/services/Permissions'
import ModalDialog from '@/components/ModalDialog.vue'

export default {
  name: 'ReviewsView',
  components: {
    PortalLink,
    HeadingSubtitle,
    ToggleSwitch,
    ModalDialog
  },
  data() {
    return {
      openReviews: null,
      ownedReviews: null,
      showPrevious: false,
      formatDateTime: formatDateTime,
      showClosed: Boolean(Number(localStorage.getItem("access_reviews_overview_showclosed") || 0)),
      showNewDialog: null,
      newReviewParams: {},
      permissions: permissions
    };
  },
  methods: {
    async openNewDialog() {
      this.showNewDialog = await fetchFromApi(this, '/api/reviews.templates');
    },
    async startNewReview(service, quarter) {
      let [service_id, plugin] = service.split(':');
      const params = {service: service_id, quarter, plugin}
      const result = await postToApi(this, '/api/review', params);
      this.newReviewParams = {};
      this.showNewDialog = null;
      if(!result.ok) {
        this.$root.error = result.error;
      }
      await this.loadData();
    },
    mapStatus(status){
      switch(status) {
        case 0: return "Initializing";
        case 1: return "Ready";
        case 2: return "Active";
        case 3: return "Signed-off";
        case 4: return "Verifying";
        case 5: return "Verification failed";
        case 6: return "Verified 100%";
        case 7: return "Archived";
        case 11: return "Error";
        default: return "Unknown";
      }
    },
    async loadData() {
      this.$root.loading = true;
      let personalReviews = await fetchFromApi(this, '/api/reviews.personal');
      this.openReviews = personalReviews.filter(x=>!x.is_service_owner)
      this.ownedReviews = personalReviews.filter(x=>x.is_service_owner)
      this.$root.loading = false;
    }
  },
  async beforeMount() {
    await this.loadData();
  },
  watch: {
    showClosed(newValue) {
      localStorage.setItem("access_reviews_overview_showclosed", Number(newValue))
    }
  }
}
</script>

<style scoped lang="scss">
  h4.subtitle {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 1ex;
    margin-top: 2em;
  }

  .data-table {
    margin-top: 0ex;

    tr {
      transition: all 0.2s ease-in-out;
    }

    tr.closed {
      color: #999;
    }
  }
</style>