<template>
    <h1 class="heading-title">
        <i v-if="icon" :class="icon"></i>
        {{ text }}
    </h1>
</template>

<script>
    export default {
        name: "HeadingTitle",
        props: {
            text: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                default: null
            }
        },
    }
</script>

<style>
    .heading-title {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
        font-weight: 900;
        font-size: 2.185rem;
        line-height: 2.75rem;
        -webkit-font-smoothing: antialiased;
        color: #FFF;
        background: #00C2E8;
        margin: 0;
        padding: 10px;
        text-transform: capitalize;
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        border-radius: 5px;
    }

    .heading-title i {
        margin-right: 8px;
    }
</style>
