<template>
    <p class="short-paragraph" @click="onClick">{{ text }}</p>
</template>

<script>
    export default {
        name: "ShortParagraph",
        props: {
            text: {
                type: String,
                required: true
            },
            onClick: {
                type: Function,
                required: false
            }
        },
    }
</script>

<style>
    .short-paragraph {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Roboto", "Open Sans", sans-serif;
        font-size: 1rem;
        line-height: 1.5rem;
        margin: 0;
        text-overflow: ellipsis;
        white-space: pre-line;
        margin: 1em 0;
    }

    .short-paragraph:first-of-type {
        margin-top: 0;
    }

    .short-paragraph:last-of-type {
        margin-bottom: 0;
    }
</style>
