<template>
  <header id="head-wide" >
    <!-- app title -->
    <div id="applogo">
      <div class="tile">
      <router-link to="/">
        <svg id="wolt-logo" viewBox="0 0 720 262">
          <path
            d="M411.996 159.402c-12.655-9.343-18.116-26.236-13.633-52.765.14-.84.502-2.324.98-4.138-2.635-1.049-5.617-1.498-8.976-1.498-29.86 
            0-46.485 50.303-46.485 97.062 0 24.374 9.528 36.655 23.763 36.655 19.738 0 37.879-34.26 44.351-75.316m246.149-47.158s-14.268 68.612-14.268 
            93.99c0 17.638 8.218 23.682 18.37 23.682 12.837 0 24.623-8.102 35.6-28.21 2.52-4.615 11.736-1.727 16.57 3.835 2.106 2.426-.318 7.317-3.638 
            12.913C694.144 246.502 670.643 262 646.225 262c-26.6 0-42.953-12.398-47.05-43.758C583.917 242.244 559.498 262 531.516 262c-34.02 
            0-48.5-18.827-48.5-52.607 0-22.281 7.505-60.23 7.505-60.23-9.966 8.981-21.522 15.475-34.824 18.172C442.624 228.594 407.632 262 
            360.613 262c-39.819 0-67.176-20.926-67.176-71.113 0-61.952 39.45-117.15 101.346-117.15 35.243 0 60.825 18.91 63.078 62.92 
            14.492-2.446 27.884-12.369 39.545-26.498 5.248-33.237 12.748-71.145 17.325-92.536.953-4.45 2.579-12.797 10.504-15.267 8.87-2.768 
            21.537-3.256 34.857-.608 7.384 1.467 9.261 8.367 9.261 14.533 0 16.397-7.769 52.57-21.278 85.1-9.574 36.262-16.142 72.442-16.142 
            101.05 0 20.673 6.84 27.485 18.309 27.485 18.72 0 40.466-26.001 50.939-61.226 1.626-16.944 5.916-44.198 
            8.068-54.357-11.597.557-19.01.894-19.01.894-6.034.221-11.883-2.323-11.586-7.71.394-7.096 1.37-13.925 
            2.565-18.734 1.196-4.809 3.938-7.793 9.768-7.896 6.255-.11 15.25-.037 24.516.185 3.122-17.875 5.746-31.825 8.266-43.421 
            1.007-4.641 5.35-7.313 9.82-7.815 5.35-.6 21.291-.487 28.23 1.196 6.986 1.698 9.913 7.185 8.702 12.735-2.472 11.303-7.526 39.04-7.526 
            39.04a6147.07 6147.07 0 0 1 46.66 2.252c7.475.335 11.327 4.544 10.131 11.125-2.016 11.055-10.246 14.608-21.875 14.875-13.262.304-27.21.731-39.765 
            1.185M299.32 23.772c0 77.229-41.424 237.729-105.603 237.729-48.42 0-58.144-54.148-60.644-123.13-24.267 52.943-37.112 80.43-53.148 107.952C72.35 
            259.32 63.246 262 53.302 262c-8.757 0-31.342-2.926-36.603-6.19-5.261-3.262-8.153-6.21-10.73-18.68-11.925-57.698-4.212-132.317 7.642-191.802 
            2.071-10.393 4.737-15.415 11.917-20.121 7.659-5.019 24.632-5.373 35.686-5.566 5.868-.103 9.279 4.121 7.803 11.274-10.677 51.94-23.21 123.105-13.445 
            196.466 0 0 36.936-73.192 75.224-164.314 7.685-18.291 9.405-24.297 16.715-25.917 9.568-2.12 16.467-2.13 26.102-.959 6.357.774 9.553 2.432 
            9.309 11.24-.667 23.745-2.257 50.615-2.257 78.511 0 47.204 5.064 100.27 23.246 100.27 24.419 0 45.87-113.537 38.294-211.097-.524-6.738 
            1.912-13.583 9.113-14.33C254.925.412 262.227 0 270.457 0c18.748 0 28.864 3.343 28.864 23.772"
          ></path>
        </svg>
        <svg id="dd-logo" viewBox="0 0 24 24"><path d="M23.071 8.409a6.09 6.09 0 0 0-5.396-3.228H.584A.589.589 0 0 0 .17 6.184L3.894 9.93a1.752 1.752 0 0 0 1.242.516h12.049a1.554 1.554 0 1 1 .031 3.108H8.91a.589.589 0 0 0-.415 1.003l3.725 3.747a1.75 1.75 0 0 0 1.242.516h3.757c4.887 0 8.584-5.225 5.852-10.413"/></svg>
      </router-link>
      </div>
    </div>

    <!-- search bar -->
    <AppSearch v-if="userPermissions.showSearch"/>

    <!-- user profile menu -->
    <a id="userprofile" @click="showProfileMenu = true" :class="{active: showProfileMenu}">
      <div v-if="userPermissions.user && userPermissions.user.imageurl" :style="'background-image: url( '+ userPermissions.user.imageurl +'); background-size: contain;color: transparent;'">
          {{ userInitials }}
      </div>
      <div v-else>{{ userInitials }}</div>
    </a>
  </header>
  <!-- Render mobile view header -->
  <CompactPageHeader :userPermissions="userPermissions"/>
 
  <UserProfileMenu v-model="showProfileMenu" :isSignedIn="isSignedIn" :userName="userName" :email="email"  v-if="showProfileMenu"/>
</template>

<script>
import { getUser, getSignedIn } from "@/services/SecurityService";
import AppSearch from '@/components/AppSearch.vue'
import UserProfileMenu from '@/components/header/UserProfileMenu.vue'
import CompactPageHeader from '@/components/header/CompactPageHeader.vue'

export default {
  name: "AppHeader",
  props: ['userPermissions'],
  components: {
    AppSearch,
    UserProfileMenu,
    CompactPageHeader
  },
  data() {
    return {
      showProfileMenu: false,
      isSignedIn: true,
      userInitials: "",
      userName: "",
      email: "",
    };
  },
  async mounted() {
    try {
      this.isSignedIn = await getSignedIn();
      if(this.isSignedIn) {
        let idToken = await getUser();
        this.userInitials = idToken.profile.given_name.substring(0, 1) + idToken.profile.family_name.substring(0, 1);
        this.userName = idToken.profile.name;
        this.email = idToken.profile.email;
      }
    } catch(err) {
      console.log(err);
    }
  }
};
</script>

<style lang="scss" scoped>
#head-wide {
  position: fixed;
  width: 100vw;
  box-sizing: border-box;
  top: 0;
  height: 61px;
  z-index: 100;
  
  display: flex;
  flex-wrap: nowrap;
  align-items: center;
  gap: 1em;
  
  padding: .7ex 2ex .7ex 0;
  background-color: var(--theme-accent);
  color: var(--theme-text-color);

  box-shadow: var(--theme-header-shadow);
}

// app logo
#applogo { 
  width: 200px;
  display: inline-flex;

  .tile {
    display: inline-flex;
    width: 75px;
    margin-right: 1em;
  }

  a {
    display: inline-flex;
  }
 
  svg {
    fill: var(--theme-text-color);
    scale: 0.5;
    width: 100%;
    &#wolt-logo {
      margin-top: -1px;
    }

    &#dd-logo {
      display: none;
      scale: 0.4;
    }
  }

  &::after {
    content: "IT Services";
    position: relative;
    
    font-size: 16px;
    font-weight: 300;
    letter-spacing: 1px;
    align-content: center;
  }
}

// user profile
#userprofile {
  cursor: pointer;

  // align right in header
  margin-left: auto
}

#userprofile div {
  font-size: 15px;
  font-weight: 500;
  vertical-align: middle;
  margin-left: 1ex;
  display: inline-block;
  border-radius: 50%;
  width: 2.7em;
  height: 2.7em;
  line-height: 3em;
  background: rgb(0, 157, 224);
  color: white;
  text-align: center;
  user-select: none;

  font-weight: 400;
  transition: all ease .6s;
}

#userprofile:hover div, #userprofile.active div {
  filter: brightness(0.8);
}

</style>