<template>
    <div class="comments" @mousemove="showComment($event, pos, false, 'hiringmanager', text)" @mouseleave="hideComment($event, pos)">
        {{ text }}
    </div>
 
    <!-- comment popup -->
    <div class="plan-meta hcp-popout" v-click-outside="closeCommentPopup" v-if="showCommentPopup" :style="{top: `${showCommentPopup.y}px`, left: `${showCommentPopup.x}px`}">
      <div class="popout-content" style="min-width: 250px; display:grid; padding: 2px; box-sizing: border-box; grid-template-columns: 1fr auto; font-size:13px;">
        <template v-if="showCommentPopup.edit">
          <textarea v-if="showCommentPopup.datafield == 'hiringmanager'" class="comments-edit" v-model="showCommentPopup.pos.extra_data.comments" focus></textarea>
          <textarea v-if="showCommentPopup.datafield == 'approver'" class="comments-edit" v-model="showCommentPopup.pos.extra_data.approver_comments" focus></textarea>
        </template>
        <template v-else>
          <pre style="max-width: 80ex; white-space: pre-wrap; font-family: roboto;">{{ showCommentPopup.comment }}</pre>
        </template>
        
      </div>
    </div>
</template>

<script>
    export default {
        name: "CommentPopup",
        props: ["text"],
        data() {
            return {
                showCommentPopup: false,
            }
        },
        methods: {
            showComment($event, pos, editMode, datafield, commentValue) {
                if (editMode || ($event.target.scrollWidth > $event.target.clientWidth && commentValue)) {
                    const b= $event.target.getBoundingClientRect(); 
                    this.showCommentPopup = {
                        datafield: datafield,
                        comment: commentValue,
                        y:b.bottom, 
                        x:(b.left + (b.right - b.left) / 2),
                        edit: editMode,
                        pos: pos
                    }
                }
            },
            hideComment($event, pos) {
                this.showCommentPopup = null;
            },
            closeCommentPopup() {
                // event is triggered the first time when the popup opens
                if(this.showCommentPopup.closeOnClick){
                    this.showCommentPopup = null;
                } else {
                    this.showCommentPopup.closeOnClick = true;
                }
                // console.log("closeMetaDataPopup")
            },
        },
    }
</script>

<style lang="scss">
    @import "~@/styles/hcp_shared.scss";
</style>

<style scoped lang="scss">
    .comments {
        max-width: 200px;
        text-overflow: ellipsis;
        text-wrap: nowrap;
        overflow: hidden;
        white-space: nowrap;
        width: 200px;
    }
</style>
