import {getIdToken, renewToken, signOutSilent} from '@/services/SecurityService';
export const API_HOST_URL = process.env.VUE_APP_BACKEND_URL;
let currentUser = null;
// export const app = { loading: false };

// export function setLoading(loading) {
//   app.loading = loading;
// }

// export function getLoading() {
//   return app.loading;
// }

export async function authenticate(vueCompnent) {
  try {
    if (currentUser) {
      return currentUser;
    }
    // console.log("get portal user")
    let userData = await fetchFromApi(vueCompnent, '/api/authenticate');
    currentUser = userData
    return userData
    // console.log(userData)
    // currentUser = {
    //   'hasAccess': true,
    //   'canSearch': false,
    //   'showMenu': false
    // }
    // return currentUser

  } catch (error) {
    showError(error);
  }

  return null
}

export async function fetchFromApi(vueComponent, url, callback=null, params=null) {
  let data = await requestApi(vueComponent, 'GET', url, null, params, callback);
  
  if(!callback) {
    return data;
  }

  return callback(data);
}

export async function postToApi(vueComponent, url, body, params={}) {
  return await requestApi(vueComponent, 'POST', url, body, params);
}

export async function deleteFromApi(url, body=null) {
  return await requestApi(null, 'DELETE', url, body, null);
}

export async function putToApi(vueComponent, url, body, params={}) {
  return await requestApi(vueComponent, 'PUT', url, body, params);
}

export async function downloadFileFromApi(vueComponent, url, params={}) {
  return await requestApi(vueComponent, 'GET', url, null, params, null, true);
}

async function requestApi(vueComponent, method, url, body=null, params=null, contentType='application/json', isBinaryResult=false) {
  try {
    if (params !== null) {
      url += '?' + new URLSearchParams(params).toString();
    }

    if (body !== null) {
      body = JSON.stringify(body);
    }

    const idToken = await getIdToken();
    const response = await fetch(API_HOST_URL + url, {
      method: method,
      params: params,
      body: body,
      headers: {
        'Content-Type': contentType,
        'Authorization': 'bearer ' + idToken,
      }
    });

    if(!response.ok) {
      const data = await response.json();
      if(response.status == 401) {
        if(data.detail == "AuthToken has expired") {
          try {
            await renewToken();

          } catch (error) {
            if (error.toString().includes("The client specified not to prompt, but the user is not logged in")) {
              // console.log(error);
              // window.location.reload();
              // return null
              await signOutSilent();
            }
        
            throw error;
          }

          return null
        }
      }
      showError(data.message, response.status, response.statusText);
    } else {
      if(isBinaryResult) {
        const data = await response.blob();
        return data;
        // DOWNLAD BINARY CONTENT
        // let buffer = await response.arrayBuffer();
        // var bytes = new Uint8Array( buffer );
        // console.log("sdfsdf")
        // return bytes;
      } else {
        const data = await response.json();
        return data;
      }
    }

  } catch (error) {
    if(vueComponent){
      vueComponent.$root.error = error;
    } else {
      showError(error);
    }
  }

  return null
}

function showError(errorMsg, httpStatusCode = null, httpStatusText = null) {
    let text = errorMsg;
    if (httpStatusCode && httpStatusText) {
      text = `<i>${httpStatusCode}: ${httpStatusText}</i> <br/> <br/> ${errorMsg}`;
    }
    alert(text)

    // this.dialog = {
    //   show: true,
    //   title: "Error",
    //   text: text,
    //   errorStyle: true,
    //   buttonText: "Ok"
    // };
  }

export async function getDownloadUrl(url) {
  const idToken = await getIdToken();
  return API_HOST_URL + url + "?auth=" + idToken
}
  
export function formatDateTime(value) {
  if (!value) {
    return null;
  }
  const dayjs = require('dayjs'); // import date library,
  
  //return dayjs(value).format('DD.MM.YYYY HH:mm');
  return dayjs(value).format('YYYY-MM-DD HH:mm');
}

export function formatRelativeDateTime(value) {
  if (!value) {
    return null;
  }
  const dayjs = require('dayjs'); // import date library,
  var relativeTime = require('dayjs/plugin/relativeTime')
  dayjs.extend(relativeTime)

  return dayjs(value).fromNow();
}

export function formatDate(value) {
  if (!value) {
    return null;
  }
  const dayjs = require('dayjs'); // import date library,
  
  //return dayjs(value).format('DD.MM.YYYY HH:mm');
  return dayjs(value).format('YYYY-MM-DD');
}

export function registerInfiniteScoll(callback) {
  window.onwheel = window.ontouchmove = window.ontouchend = window.ontouchstart = () => {
    // use >= instead of ===. == is not reliable
    let bottomOfWindow = document.documentElement.scrollTop + window.innerHeight >= document.documentElement.offsetHeight;
    if (bottomOfWindow) {
      callback();
    }
  };
}

export function unregisterInfiniteScoll() {
  window.onwheel = window.ontouchmove = window.ontouchend = window.ontouchstart = null;
}