<template>
    <button @click="onClick" :disabled="disabled" class="btn-generic" :class="{'generic-btn-icon-only': text == ''}">
        <i v-if="icon" :class="icon"></i>
        {{ text }}
    </button>
</template>

<script>
    export default {
        name: "GenericButton",
        props: {
            text: {
                type: String,
                required: false
            },
            onClick: {
                type: Function,
                required: false
            },
            disabled: {
                type: Boolean,
                default: false
            },
            icon: {
                type: String,
                default: null
            }
        },
    }
</script>

<style>
    .btn-generic {
        font-family: system-ui, -apple-system, BlinkMacSystemFont, "Roboto", "Open Sans", sans-serif;
        font-weight: 500;
        background: var(--theme-accent);
        padding: 8px 16px;
        color: #FFF;
        border: none;
        border-radius: 8px;
        cursor: pointer;
        transition: all .2s ease-in-out;
        font-size: 1rem;
        vertical-align: middle;
        display: inline-block;
        margin: 6px 4px;
    }

    .btn-generic:hover:enabled {
        background: var(--theme-accent-hover);
    }

    .btn-generic:active:enabled {
        background: var(--theme-accent-active);
        transform: scale(0.98);
    }

    .btn-generic:disabled {
        background: var(--theme-accent);
        opacity: .5;
        cursor: not-allowed;
    }

    .btn-generic:focus {
        outline: none;
    }

    .generic-btn-icon-only {
        border-radius: 100%;
        aspect-ratio: 1 / 1;
    }

    .generic-btn-icon-only i {
        margin-right: unset;
    }
</style>
