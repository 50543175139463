<template>
<main>
  <h1><i class="fa-solid fa-ticket"></i>{{ticketId}} - {{ticketTitle}}</h1>

  <section class="highlight">
    <dl class="listing" v-if="ticketData">
      <dt>Title:</dt><dd>{{ticketData.title}}</dd>
      <dt>Requester:</dt><dd><PortalLink view="user" :item="ticketData.requester_id">{{ticketData.requester}}</PortalLink></dd>
      <dt>Requested for:</dt><dd><PortalLink view="user" :item="ticketData.requested_for_id">{{ticketData.requested_for}}</PortalLink></dd>
      <dt>Date:</dt><dd>{{formatDateTime(ticketData.date)}}</dd>
      <!-- <dt>Created:</dt><dd>{{formatDateTime(ticketData.created)}}</dd>
      <dt>Updated:</dt><dd>{{formatDateTime(ticketData.updated)}}</dd> -->
      <dt>Automation State:</dt><dd>{{ticketData.automation_state}}</dd>
      <dt>Automation Code:</dt><dd>{{mapAutomationCode(ticketData.automation_code)}} - {{ticketData.automation_code}}</dd>
      <dt>Ticket status:</dt><dd>{{mapTicketStaus(ticketData.status)}} - {{ticketData.status}}</dd>
      <dt>Last error:</dt>
      <dd><button class="subtle" v-if="ticketData.last_error" @click="showError = !showError">Show/hide error</button></dd>
    </dl>
    <Transition><pre v-if="showError" class="error-panel">{{ticketData.last_error}}</pre></Transition>
 
    <ul class="link-list" v-if="permissions.isIT">
      <li><i class="fa-solid fa-up-right-from-square"></i> <a target="_blank" :href="'https://wolt.freshservice.com/a/tickets/' + ticketId">Open in FreshService</a></li>
    </ul>
  </section>

  <h4>Ticket automation log</h4>
  <table class="data-table">
    <tr class="data-header">
      <th>Date</th>
      <th>User</th>
      <th>Level</th>
      <th>Text</th>
    </tr>
    <tr class="data-row" :class="row.level.toLowerCase()" v-for="row in ticketLog" :key="row.id">
      <td>{{formatDateTime(row.timestamp)}}</td>
      <td><PortalLink view="user" :item="row.user_id">{{row.user_id}}</PortalLink></td>
      <td>{{row.level}}</td>
      <td>{{row.text}}</td>
    </tr>
    <tr class="data-row nodata" v-if="ticketLog && ticketLog.length == 0">
      <td colspan="3">No log data found!</td>
    </tr>
  </table>
</main>
</template>

<script>
import {fetchFromApi, formatDateTime, registerInfiniteScoll, unregisterInfiniteScoll} from '@/services/AppTools.js'
import {permissions} from '@/services/Permissions.js'
import PortalLink from '@/components/PortalLink.vue';

export default {
  name: 'TicketView',
  components: {
    PortalLink
  },
  data() {
    return {
      ticketId: this.$route.params.id,
      ticketTitle: "",
      ticketData: null,
      ticketLog: [],
      cursor: null,
      permissions: permissions,
      formatDateTime: formatDateTime,
      showError: false
    };
  },
  methods: {
    async loadLogData(cursor = 0) {
      if (cursor === null) {
        return;
      }

      const params = {'cursor': cursor}

      this.$root.loading = true;
      let response = await fetchFromApi(this, `/api/ticket/${this.ticketId}/log`, null, params);
      this.ticketLog = this.ticketLog.concat(response.data);
      this.cursor = response.next_cursor
      this.$root.loading = false;
    },
    async loadData() {
      this.ticketData = await fetchFromApi(this, `/api/ticket/${this.ticketId}`);
      this.ticketTitle = this.ticketData ? this.ticketData.title : ""
    },
    mapTicketStaus(status) {
      switch (status) {
        case 2:
          return "Open";
        case 3:
          return "Pending";
        case 4:
          return "Resolved";
        case 5:
          return "Closed";
        default:
          return "";
      }
    },
    mapAutomationCode(state) {
      switch (state) {
        case 0:
          return "Open";
        case 1:
          return "Unknown";
        case 2:
          return "Granted";
        case 3:
          return "Rejected";
        default:
          return "";
      }
    }
  },
  async beforeMount() {
    await this.loadData();
    await this.loadLogData();

    registerInfiniteScoll(() => { if(!this.$root.loading) { this.loadLogData(this.cursor) } });
  },
  async beforeUnmount() {
    unregisterInfiniteScoll();
  }
}
</script>

<style lang="scss" scoped>
@import "~@/styles/_variables.scss";

.error {
  color: $itp-red100;
  font-weight: 500;
}

.warning{
  color: $itp-orange;
}

.data-table {
  td:nth-child(1) { // date column
    min-width: 16ex; // no text wrapping
  }
}

.error-panel {
  font-size: 12px;
  background: white;
  padding: 1ex;
  color: red;
  border: 1px solid gray;
  filter: brightness(0.95);
  line-height: 1.5em;
  overflow-x: auto;
}

// vue transition anmiation
.v-enter-active, .v-leave-active {
  transition: opacity 0.5s ease;
}

.v-enter-from, .v-leave-to {
  opacity: 0;
}

</style>
