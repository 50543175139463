<template>
  <table class="data-table">
    <thead>
    <tr>
      <th>Plugins / sections</th>
      <th>Info</th>
      <th>Warning</th>
      <th>Error</th>
      <th>Critical</th>
    </tr>
    </thead>
    <tr class="data-row" v-for="section in details" :key="section.name">
      <td>{{section.name}}</td>
      <td>{{section.info}}</td>
      <td>{{section.warning}}</td>
      <td class="r-error">{{section.error}}</td>
      <td class="r-error" style="font-weight:bold">{{section.critical}}</td>
    </tr>
  </table>
</template>

<script>
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
export default {
  name: 'RecentTickets',
  components: {
    // PortalLink
  },
  data() {
    return {
      details: null,
      formatDateTime: formatDateTime
    };
  },
  async beforeMount() {
    const userId = this.$route.params.id;

    this.$root.loading = true;
    this.details = await fetchFromApi(this, '/api/automations/2/current'); // 2 is ticket automation
    this.$root.loading = false;
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
.ribbon {
  padding: 0.2rem 0.5rem;
  margin: 0 0.2rem;
  font-size: 100%;
  white-space: nowrap;
  // background: #F1F1F1;
  // border: 1px solid #E1E1E1;
  border-radius: 18px;
  font-family: "Consolas", monospace;
  font-weight: 700;
  font-size: 1em;
  text-transform: uppercase;
}

.r-ok {
  // background: #d6f5d6;
  // border: 1px solid #84e184;
  color: green;
}
.r-error {
  // background: #ffb3b3;
  // border: 1px solid #ff4d4d;
  color: red;
}
.r-warn {
  background: #fff0b3;
  border: 1px solid #ffdb4d;
}

table.compact {
  width: 100%;
  border-spacing: 0;
  td {
    border: 0;
  }
}
</style>
