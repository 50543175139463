<template>
  <div class="modal-overlay" >
  <div class="details-modal" :class="this.class">
    <div v-show="hideClose != true" class="details-modal-close" @click="close()">
      <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" fill="none">
        <path fill-rule="evenodd" clip-rule="evenodd" d="M13.7071 1.70711C14.0976 1.31658 14.0976 0.683417 13.7071 0.292893C13.3166 -0.0976311 12.6834 -0.0976311 12.2929 0.292893L7 5.58579L1.70711 0.292893C1.31658 -0.0976311 0.683417 -0.0976311 0.292893 0.292893C-0.0976311 0.683417 -0.0976311 1.31658 0.292893 1.70711L5.58579 7L0.292893 12.2929C-0.0976311 12.6834 -0.0976311 13.3166 0.292893 13.7071C0.683417 14.0976 1.31658 14.0976 1.70711 13.7071L7 8.41421L12.2929 13.7071C12.6834 14.0976 13.3166 14.0976 13.7071 13.7071C14.0976 13.3166 14.0976 12.6834 13.7071 12.2929L8.41421 7L13.7071 1.70711Z" fill="black" />
      </svg>
    </div>
    <div class="details-modal-title">
      <h1>{{title}}</h1>
    </div>
    <div class="details-modal-content">
      <slot></slot>
    </div>     
    <div class="modal-actions">
      <slot name="buttons"></slot>
    </div>
  </div>
  </div>
</template>

<script>
  export default {
    name: 'ModalDialog',
    props: ['title', 'hideClose', 'class'],
    emits: ['close'],
    methods: {
      close() {
        this.$emit('close');
      }
    },
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 @keyframes fadeIn {
    0% {
      opacity: 0.0;
    }
    100% {
      opacity: 1;
    }
  }

  .modal-overlay {
    /* transition: opacity 0.2s ease-out; */
     animation: .2s ease-out fadeIn;
    pointer-events: none;
    background: rgba(15, 23, 42, 0.4);
    position: fixed;
    
    z-index: 1001;
    bottom: 0;
    right: 0;
    left: 0;
    top: 0;

    /* opacity: 0.5; */
    pointer-events: all;

    display: flex;
    justify-content: center;
    align-items: center;
  }

  .details-modal {
    background: #ffffff;
    border-radius: 0.5em;
    box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
    // left: 50%;
    max-width: 90%;
    pointer-events: none;
    position: fixed;
    // top: 50%;
    // transform: translate(-50%, -50.2%); // -50 might cause blurry table borders
    width: 30em;
    text-align: left;
    max-height: 90vh;
    display: flex;
    flex-direction: column;
    z-index: 1002;
    font-family: 'Roboto',sans-serif;
    font-size: 18px;
  }
  .details-modal .details-modal-close {
    align-items: center;
    color: #111827;
    display: flex;
    height: 4.5rem;
    justify-content: center;
    /* pointer-events: none; */
    position: absolute;
    right: 0;
    top: 0;
    width: 4.5rem;
    cursor: pointer;
    pointer-events: all;
  }
  .details-modal .details-modal-close svg {
    display: block;
  } 

  .details-modal .details-modal-title {
    color: #111827;
    padding: 1.5rem 2rem;
    pointer-events: all;
    position: relative;
    width: calc(100% - 4.5rem);
    height: 4.5rem;
    box-sizing: border-box;
  }
  .details-modal .details-modal-title h1 {
    font-size: 1.25rem;
    font-weight: 600;
    line-height: normal;
    margin: 0;
  }
  .details-modal .details-modal-content {
    border-top: 1px solid #e0e0e0;
    padding: 1.5rem 2rem;
    pointer-events: all;
    overflow: auto;
  }

  .details-modal .details-modal-content :first-child
  {
    margin-top: 0;
  }


</style>
<style lang="scss">
  /* Styles that can be used inside the modal content */

 .modal-actions {
    display: flex;
    justify-content: right;
    gap: 1ex;
    align-items: center;
    // margin-top: 1em;
    padding-top: 1rem;
    padding: 1.5rem 2rem;
    pointer-events: all;
  }
</style>