<template>
    <HeadingTitle :text="text" :icon="icon" class="heading-title-sub" :class="{'heading-title-sub-animate': !disableAnimation}" />
</template>

<script>
    import HeadingTitle from '@/components/objects/text/HeadingTitle.vue';

    export default {
        name: "HeadingSubtitle",
        components: {HeadingTitle},
        props: {
            text: {
                type: String,
                required: true
            },
            icon: {
                type: String,
                default: null
            },
            disableAnimation: {
                type: Boolean,
                default: false,
                required: false,
            }
        },
    }
</script>

<style>
    .heading-title-sub {
        background: unset;
        color: rgb(46, 46, 46);
        font-size: .75em;
        letter-spacing: -.5px !important;
        text-transform: uppercase !important;
        padding: 0;
        margin: 0;
        line-height: 2em;
        text-transform: unset;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-rendering: optimizeLegibility;
        text-shadow: rgba(0,0,0,.01) 0 0 1px;
    }

    .heading-title-sub-animate {
        animation: heading-title-sub-show-up .7s ease;
    }

    @keyframes heading-title-sub-show-up {
        0% {
            opacity: 0;
            transform: translateX(-10px);
        }
        100% {
            opacity: 1;
            transform: translateY(0);
        }
    }
</style>
