<template>
  <h4>FreshService User</h4>
  <table v-if="fsUser !== null">
    <tr>
      <td>ID</td>
      <td><a :href="`${fsUrl}/users/${fsUser.id}`">{{ fsUser.id }}</a></td>
    </tr>
    <tr>
      <td>Email</td>
      <td>{{ fsUser.primary_email }}</td>
    </tr>
    <tr>
      <td>Location</td>
      <td>{{ fsUser.location_name }} ({{ fsUser.location_id }})</td>
    </tr>
    <tr>
      <td>Is an agent</td>
      <td>{{ fsUser.is_agent ? "Yes" : "No" }}</td>
    </tr>
  </table>
  <h4>Assets</h4>
  <table v-if="assets !== null" class="assets-table">
    <tr>
      <th>&nbsp;<!-- Icon --></th>
      <th>Name</th>
      <th>Relation</th>
      <th>Assigned on</th>
    </tr>
    <tr v-for="asset in assets" :key="asset.id" >
      <td :title="asset.device_type"><i :class="getIcon(asset.device_type)"></i></td>
      <td><a :href="`${fsUrl}/cmdb/items/${asset.display_id}`">{{ asset.name }}</a></td>
      <td>{{ getRelation(asset) }}</td>
      <td>{{
        getRelation(asset).includes('assigned') ?
          (new Date(asset.assigned_on)).toLocaleString("en-US") :
          'N/A'
      }}</td>
    </tr>
  </table>
</template>

<script>
import { fetchFromApi } from '@/services/AppTools.js'
export default {
  name: 'UserAssets',
  components: {

  },
  data() {
    return {
      
      assets: null,
      fsUser: null,

      fsUrl: process.env.NODE_ENV === 'production' ? 'https://wolt.freshservice.com' : 'https://wolt-fs-sandbox.freshservice.com',

    };
  },

  methods: {

    async fetchUserAssets() {
      const userId = this.$route.params.id;

      const url = `/api/user/${userId}/devices`;
      const response = await fetchFromApi(null, url);

      console.log(response);

      return response;
    },


    getIcon(deviceType) {
      switch (deviceType) {
        case 'Desktop':
          return 'fa-solid fa-desktop';
        case 'Mobile':
          return 'fa-solid fa-mobile-screen-button';
        case 'Laptop':
          return 'fa-solid fa-laptop';
        case 'Hardware':
          return 'fa-solid fa-server';
        default:
          return 'fa-solid fa-question';
      }
    },

    getRelation(asset) {
      let relations = [];

      if (asset.user_id === this.fsUser.id) {
        relations.push('assigned');
      }

      if (asset.agent_id === this.fsUser.id) {
        relations.push('managing');
      }

      return relations.join(', ');
    }


  },

  async beforeMount() {
    this.$root.loading = true;


    const fsData = await this.fetchUserAssets();
    this.assets = fsData.assets;
    this.fsUser = fsData.user;

    this.$root.loading = false;
  }
}
</script>


<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>

  table {
    width: 100%;
    border-collapse: collapse;
    font-size: 1rem;
  }

  .assets-table {
    margin-top: 0;
  }

  .assets-table th {
    text-align: left;
    padding: 0.5rem;
    border-bottom: 1px solid #ccc;
  }

  .assets-table tr:first-of-type,
  .assets-table td:first-of-type {
    width: 20px;
  }

  .assets-table td {
    padding: 0.5rem;
  }

</style>
