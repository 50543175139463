<template>
  <div id="usermenu">
    <div id="userprofile-overlay" @click="this.$emit('update:modelValue', false)"></div>
    <div id="userprofile-menu" class="popout">
      <div class="popout-section">{{ userName }}<br/><span class="email">{{ email }}</span></div>

      <div class="popout-section">
        <button @click="signOut()" v-if="isSignedIn" style="display: inline;">Sign Out</button>
        <button @click="signIn()" v-if="!isSignedIn" style="display: inline;">Sign In</button>
      </div>
      <div v-if="permissions.isIT" class="popout-section">
        <select v-model="selectedTheme" @change="onThemeChange">
          <option value="">Wolt</option>
          <option value="env-orange">Wolt Orange</option>
          <option value="env-pink">Wolt Pink</option>
          <option value="env-wolt-doge">Wolt Doge</option>
          <option value="env-dd-prod">Doordash</option>
        </select>
      </div>
      <div class="app-version" :title="appSemVer"><span>v{{appVersion}}</span> <span>{{appVersionHash}}</span></div>
    </div>
  </div>
</template>
  
<script>
import { signOutSilent as authSignOut, signIn as authSignIn } from "@/services/SecurityService";
import { permissions } from "@/services/Permissions";

export default {
  name: 'UserProfileMenu',
  props: ['modelValue', 'userName', 'email', 'isSignedIn'],
  emits: ['update:modelValue'],
  data() {
    return {
      showProfileMenu: false,
      appVersion: process.env.VUE_APP_GIT_VERSION,
      appSemVer: process.env.VUE_APP_GIT_SEMVER,
      appVersionHash: process.env.VUE_APP_GIT_VERSION_HASH,
      permissions: permissions,
      selectedTheme: window.localStorage.getItem("it-portal-theme"),
    };
  },
  methods: {
    signOut() {
      authSignOut();
      location.href = "/";
    },
    signIn() {
      authSignIn();
    },
    onThemeChange() {
      window.localStorage.setItem("it-portal-theme", this.selectedTheme);
      document.location.reload();
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
#userprofile-overlay {
  pointer-events: none;
  background: rgba(15, 23, 42, 0);
  position: fixed;

  z-index: 101;
  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  opacity: 0.5;
  pointer-events: all;
}
@keyframes userprofile-menu-slide-down {
  0% {
    opacity: 0;
    transform: translateY(-15%);
    height: 115px;
  }
  100% {
    opacity: 1;
    height: 130px;
    transform: translateY(0);
  }
}
#userprofile-menu {
  z-index: 102;
  text-align: center;
  min-width: 10em;
  position: fixed; 
  right: 1ex;
  font-size: 15px;
  line-height: 100%;
  top: 70px;
  height: 130px;
  animation: .2s ease-out 1 forwards userprofile-menu-slide-down;
}

#userprofile-menu .email {
  font-size: smaller;
}

.logout {
  order: 1;
  padding-top: 7px;

  font-size: 14px;
}

.logout svg {
  transition: fill 96ms ease-in 0s;
  width: 12px;
  height: 12px;
}

.popout {
  min-height: 30px;
  padding: 0px;
  color: rgb(32, 33, 37);
  border-radius: 8px;
  box-sizing: border-box;
  background-color: rgb(255, 255, 255);
  box-shadow: rgb(32 33 37 / 8%) 0px 0px 0px 1px,
    rgb(32 33 37 / 12%) 0px 4px 14px -2px;
}

.popout-section {
  padding: 12px 16px;
  flex-shrink: 0;
}

.app-version {
  color: gray;
  font-size: 10px;
  height: 15px;
  line-height: 10px;
  text-align: left;
  padding-right: 1rem;
  display: flex;
  justify-content: space-between;
}

.app-version span {
  margin-left: 1em;
}
</style>