<template>
  <header id="head-compact">
    <button class="subtle-gray" title="Show menu"  @click="showMenuOverlay=true">
      <i class="fa-solid fa-bars"></i>
    </button>
    <button class="subtle-gray" title="Show search" @click="showSearchOverlay=true" v-if="userPermissions.showSearch">
      <i class="fa fa-search"></i>
    </button>
    <button class="subtle-gray" title="Show user menu"  @click="showProfileMenu=true">
      <i class="fa-regular fa-circle-user"></i>
    </button>
  </header>

  <UserProfileMenu v-model="showProfileMenu" :isSignedIn="isSignedIn" :userName="userName" :email="email"  v-if="showProfileMenu"/>

  <div id="search-overlay" v-if="showSearchOverlay">
    <div style="height: 42px; display: flex; justify-content: left; padding: 15px">
      <button class="subtle-gray" title="Close overlay" @click="showSearchOverlay=false">
        <i class="fa-solid fa-xmark menu-overlay-exit"></i>
      </button>
    </div>
    <div class="menu-overlay-greeting">
      <div class="menu-overlay-greeting-text">
        <div class="menu-overlay-greeting-avatar">
          <i class="fa fa-search"></i>
        </div>
        <div class="menu-overlay-greeting-text-name">Search in <span>IT Portal</span></div>
      </div>
    </div>
    <AppSearch v-if="userPermissions.showSearch"/>
  </div>

  <div id="menu-overlay" v-if="showMenuOverlay">
    <div style="height: 42px; display: flex;justify-content: left; padding: 15px 3px">
      <button class="subtle-gray" style="color:white" title="Close overlay" @click="showMenuOverlay=false">
        <i class="fa-solid fa-xmark menu-overlay-exit"></i>
      </button>
    </div>
    <div class="menu-overlay-greeting">
      <div class="menu-overlay-greeting-text">
        <div class="menu-overlay-greeting-avatar">
          <i class="fa-regular fa-circle-user"></i>
        </div>
        <div class="menu-overlay-greeting-text-name">Hello, <span>{{ userName.split(" ")[0] }}</span>!</div>
      </div>
    </div>
    <AppMenu @click="showMenuOverlay=false;" :compact="true"/>
  </div>
</template>

<script>
import { getUser, getSignedIn } from "@/services/SecurityService";
import AppMenu from '@/components/AppMenu.vue';
import AppSearch from '@/components/AppSearch.vue'
import UserProfileMenu from '@/components/header/UserProfileMenu.vue'

export default {
  name: "AppHeader",
  props: ['userPermissions', 'loading'],
  components: {
    AppMenu,
    AppSearch,
    UserProfileMenu
  },
  data() {
    return {
      showProfileMenu: false,
      isSignedIn: true,
      userInitials: "",
      userName: "",
      email: "",
      showSearchOverlay: false,
      showMenuOverlay: false
    };
  },
  async mounted() {
    let self = this;
    try {
      this.isSignedIn = await getSignedIn();
      if(this.isSignedIn) {
        let idToken = await getUser();
        self.userInitials = idToken.profile.given_name.substring(0, 1) + idToken.profile.family_name.substring(0, 1);
        self.userName = idToken.profile.name;
        self.email = idToken.profile.email;
      }
    } catch(err) {
      console.log(err);
    }
  }
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>

#head-compact {
  position: fixed;
  top: 0;
  background-color: white;
  z-index: 100;

  padding: .7ex 1.5ex;
  display: flex;
  flex-wrap: nowrap;
  justify-content: space-between;

  height: 61px;
  width: 100%;
  box-sizing: border-box;
  /* padding: 1ex; */
}

#search-overlay, #menu-overlay {
  position: fixed;
  z-index: 300;

  bottom: 0;
  right: 0;
  left: 0;
  top: 0;

  animation: .2s ease-out fadeIn;
  background: rgba(248, 248, 248, 0.2);
  -webkit-backdrop-filter: blur(20px);
  backdrop-filter: blur(20px);
}

#menu-overlay .appnav {
  top: 0!important;
}

.menu-overlay-exit {
  color: #000;
}

.menu-overlay-greeting {
  font-size: 1rem;
  text-align: center;
}
.menu-overlay-greeting-text-name {
  font-weight: 500;
  margin-bottom: 20px;
  font-weight: 300;
}
.menu-overlay-greeting-text-name span {
  color: rgb(0, 157, 224);
  font-weight: 500;
}
.menu-overlay-greeting-avatar {
  font-size: 1.6em;
  margin-bottom: 10px;
  margin-top: -49px;
}

</style>