<template>
<main v-if="user">
  <div class="heading">
    <h1>
      <span class="heading-segment">Hi</span> <span class="heading-segment heading-firstname">{{ user.firstname }}</span> <span class="heading-segment">how can we help you today?</span></h1>
  </div>

  <div class="tabs">
    <HeadingSubtitle text="Take a shortcut" />
    <router-link :to="'/user/' + permissions.user.id" v-if="permissions.isIT">
      <div class="tab">
        <i class="fa-solid fa-user"></i>
        <span>My User</span>
      </div>
    </router-link>
    <a href="https://wd3.myworkday.com/wolt" target="_blank">
      <div class="tab tab-external">
        <img src="/static/icons/workday-icon.png" alt="W" />
        <span>Workday</span>
        <i class="fa-solid fa-up-right-from-square"></i>
      </div>
    </a>
    <a href="https://wolt.freshservice.com/support/home" target="_blank">
      <div class="tab tab-external">
        <i class="fa-solid fa-ticket-simple"></i>
        <span>Fresh Service</span>
        <i class="fa-solid fa-up-right-from-square"></i>
      </div>
    </a>
    <a href="https://woltwide.atlassian.net/wiki/spaces/PEOP/pages/227737604/IT" target="_blank">
      <div class="tab tab-external">
        <img src="/static/icons/confluence-icon.png" alt="=" />
        <span>Confluence IT</span>
        <i class="fa-solid fa-up-right-from-square"></i>
      </div>
    </a>
  </div>

  <!--<div class="no-reviews-placeholder" v-if="!openReviews || !openReviews.length">
      <i class="fa-solid fa-ghost"></i>
      <p>It's a bit lonely here...</p>
  </div>-->

  <div class="open-reviews">
    <HeadingSubtitle text="Access reviews" />

    <ul v-if="openReviews && openReviews.length" class="item-list compact">
      <li v-for="row in openReviews" :key="row.review_id">
        <router-link :to="'/reviews/' + row.review_id">
          {{row.service_name}}: {{row.title}} <span v-if="row.period_start">({{row.period_start}} - {{row.period_end}})</span>
        </router-link>
      </li>
    </ul>

    <span class="open-reviews-none" v-else>There are no open reviews assigned to you.</span>

  </div>


</main>
</template>

<script>
import { fetchFromApi } from '@/services/AppTools.js'
import { permissions } from '@/services/Permissions.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';

export default {
  name: 'HomeView',
  components: {
    HeadingSubtitle,
  },
  data() {
    return {
      user: null,
      openReviews: null,
      permissions: permissions,
      logoSource: "/static/w_logo_anim.gif"
    };
  },
  async beforeMount() {
    // set special css class on app root
    this.$root.appClass["home"] = true;

    // load data
    this.user = await fetchFromApi(this, '/api/user/me');
    let reviews =  await fetchFromApi(this, '/api/reviews.personal', null);
    this.openReviews = reviews.filter(x=>!x.locked && !x.is_service_owner);

    setInterval(() => {
      this.logoSource = "/static/w_logo_transparent.png";
    }, 2200);
  },
  async beforeUnmount() {
    // remove special css class on app root
    this.$root.appClass["home"] = false;
  }
}
</script>

<!-- Unscoped css for out of component overwrites -->
<style lang="scss">
  // .tile {
  //   height: 60px;
  //   background-color: #fafafa;
  //   z-index: 200;
  // }
.home #head-wide {
  position: inherit;

  #search-container {
    position: absolute;
    margin-left: calc(50% - 8.5em);
    top: 203px;
    min-width: 17em!important;
    left: 0;
    color: black;
    padding: 0px 3px;
    height: inherit;
    
    z-index: 100;
    #searchBox {
      box-shadow: 0 4px 6px 0 rgba(0,0,0,0.2);
    }
    
    #outerSearch {
      background-color: white;
      border-color: white;
      color: black;

      input, input::placeholder {
        color: black;
      }
    }
  }
}

.home .meter {
  display: none;
}
</style>

<style scoped>
main {
  padding-top: 11rem;
}



.heading {
  width: 100%;
  height: 110px;
  background: rgb(1, 192, 229);
  background: linear-gradient(180deg, rgb(0, 194, 232) 100%, rgb(0, 135, 161) 0%);
  left: 0;
  top: calc(var(--header-height) - var(--loading-bar-height));
  overflow: hidden;
  white-space: nowrap;
  position: absolute;
  text-align: center;
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, sans-serif;
  padding-top: 3em;
  padding-bottom: 3em;
  margin-top: 5px;
}

@media only screen and (max-width: 600px) {
  main {
    padding-top: 3rem;
  }
  .heading {
    padding-top: 2em;
    padding-bottom: 0em;
  }
}

.heading-segment {
  color: #fff;
  display: inline-block;
  opacity: 0;
  letter-spacing: -1.5px;
}

.heading-segment:nth-child(1) {
  animation: heading-fade-in-from-bottom .6s ease 1 forwards;
}

.heading-segment:nth-child(2) {
  animation: heading-fade-in-from-top .6s ease 1 forwards;
  animation-delay: .4s;
}

.heading-segment:nth-child(3) {
  animation: heading-fade-in-from-bottom .6s ease 1 forwards;
  animation-delay: .8s;
}

.heading-segment:not(:last-of-type)::after {
  content: ", ";
}

.reviews-count {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-size: .7em;
  color: #ffffff;
  margin-top: 0;
  padding-top: 0;
  opacity: 0;
  animation: heading-fade-in-from-top .6s ease 1 forwards;
  animation-delay: 1.2s;
  font-style: italic;
  font-weight: 500;
}

.heading h1 {
  margin-top: -20px;
  font-weight: 700;
  margin-bottom: 5px;
}

.heading-firstname {
  background: radial-gradient(ellipse at center, #fff 0%, #fff 50%, #fff 50%, #fff 100%);
  -webkit-background-clip: text;
  background-clip: text;
  -webkit-text-fill-color: transparent;
}

.heading-segment:nth-child(2)::after {
  -webkit-background-clip: none;
  background-clip: none;
  -webkit-text-fill-color: #fff;
}

.tabs {
  padding-top: 110px;
}

.tabs .tab {
  width: 185px;
  height: 110px;
  line-height: calc(110px / 2);
  background: #fff;
  box-shadow: 0 0 10px rgba(97, 97, 97, 0.1);
  display: inline-block;
  vertical-align: top;
  margin: 10px 15px 20px 0;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  color: #161616;
  transition: all ease .2s;
  opacity: 0;
  animation: tab-appear .4s ease 1 forwards;
}

.tabs .tab img {
  max-width: 25px;
  padding: 0;
  vertical-align: middle;
  margin-bottom: -15px;
}

.tabs .tab img + span {
  margin-top: -6px;
}

.tabs a:nth-child(odd) .tab {
  animation-delay: .1s;
}

.tabs a:last-of-type .tab {
  margin-right: 0;
}

.tabs .tab i, .tabs .tab span {
  display: block;
  font-size: .7em;
}

.tabs .tab i {
  margin-top: 25px;
  font-size: .9em;
}

.tabs .tab:hover, .tabs .tab:active {
  box-shadow: 0 0 10px rgba(97, 97, 97, 0.2);
}

.tabs a .tab:hover {
  transform: scale(1.05);
}

.tabs a .tab:active {
  transform: scale(1.02);
}

.tabs .tab-external {
  position: relative;
}

.tabs .tab-external i:last-of-type {
  font-size: .45em;
  position: absolute;
  top: 0;
  right: 0;
  margin: 5px;
}

.open-reviews-none {
  font-family: system-ui, -apple-system, BlinkMacSystemFont, Roboto, "Open Sans", sans-serif;
  font-size: .7em;
  font-weight: 500;
}

.w-logo-animation {
  max-height: 50px;
  width: auto;
  margin-left: 10px;
  margin-top: 5px;
}

.w-logo-animation.w-logo-animation-transparent {
  transform: scale(1.07);
  margin-left: 0;
}

@keyframes heading-fade-in-from-top {
  from { transform: translateY(-20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes heading-fade-in-from-bottom {
  from { transform: translateY(20px); opacity: 0; }
  to { transform: translateY(0); opacity: 1; }
}

@keyframes tab-appear {
  from { opacity: 0; }
  to { opacity: 1; }
}

@media screen and (max-width: 850px) {

  .heading {
    text-align: left;
  }

  .heading h1, .heading h2 {
    margin-left: 15px;
  }

  .heading-segment, .reviews-count {
    animation-delay: unset;
    display: inline-block;
  }

  .heading-segment:last-of-type {
    display: none;
  }

  .heading-segment:nth-child(2)::after {
    content: "!";
  }

  .w-logo-animation {
    margin-left: 17.5px;
  }

  .w-logo-animation.w-logo-animation-transparent {
    margin-left: 11.5px;
  }
}

@media screen and (max-width: 600px) {
  .heading {
    width: 100%;
    margin-left: 0;
    left: 0;
  }

  .tabs {
    padding-top: 170px;
  }
}

</style>
