<template>
    <div class="multi-user-input">
        <button class="btn-selected-user" @click="removeUser(user)" v-for="user in selectedUsers" v-bind:key="user.id">
            <i class="fa-solid fa-user"></i> {{ user.name }}
            <span class="remove-user" @click="removeUser(user)"><i class="fa-solid fa-xmark"></i></span>
        </button>

        <i class="fa-solid fa-magnifying-glass search-btn"></i>
        <div style="width: 250px; display: inline-block;" class="select-override">
            <SelectUserInput ref="searchBar" :value="selectedResult.id" :text="selectedResult.name || 'Select user...'"
                :dataCacheKey="dataCacheKey" :dataUrl="dataUrl"
                @select="selectUser($event)"></SelectUserInput>
        </div>
    </div>
</template>

<script>
    import SelectUserInput from "@/components/objects/inputs/SelectUserInput.vue";

    export default {
        name: "MultiUserInput",
        props: [
            "initialUsers",
            "dataUrl",
            "dataCacheKey"
        ],
        emits: ["select"],
        components: { SelectUserInput },

        data() {
            return {
                selectedUsers: [],
                allPeople: [],

                searchResult: [],
                selectedResult: {},
            }
        },

        methods: { 
            removeUser(user) {
                this.selectedUsers = this.selectedUsers.filter(u => u.id != user.id);
                this.$emit('select', this.selectedUsers);
            },

            selectUser(user) {
                this.selectedUsers.push(user);
                this.selectedResult = {};
                this.$refs.searchBar.reset();
                this.$emit('select', this.selectedUsers);
            },
        },

        async beforeMount() {
            if (this.initialUsers) {
                for (let user of this.initialUsers) {
                    this.selectedUsers.push({"name": user[1], "id": user[0]});
                }
            }
        },
    }
</script>

<style scoped lang="scss">
    .multi-user-input {
        display: inline-block;
        vertical-align: middle;
        border: 1px solid #ccc;
        white-space: nowrap;
        min-width: 100px;
        width: stretch;
        height: 45px;
        transition: all ease .2s;
  

        .btn-selected-user {
            display: inline-block;
            vertical-align: middle;
            // border: 1px solid #ccc;
            border-radius: 5px;
            padding: 5px;
            margin: 5px;
            width: auto;
            background-color: #eee;
            position: relative;
            color: black;
            font-weight: normal;
            font-size: 15px;
            font-family: 'Roboto';
            // line-height: 1.5em;

            i {
                margin-right: 2.5px;
                font-size: 13px;
                transform: translateY(-1px);
            }

            .remove-user {
                margin-left: 5px;
            }

            &:hover .remove-user {
                color: red;
            }
        }


        .search-btn {
            display: inline-block;
            vertical-align: middle;
            border: none;
            outline: none;
            background-color: transparent;
            padding: 0 5px;
            font-size: 1rem;
            position: relative;
            cursor: pointer;
            transform: translateY(-1px);
        }
    }
</style>

<style lang="scss">
    .select-override { 
        #myInput{
            background-color: white!important;
        }
        .search .fa-user {
            display: none!important;
        }
    }
</style>
