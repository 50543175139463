import {reactive} from 'vue'

export const permissions = reactive({
    isAdmin: true,
    isIT: false,
    isHR: false,
    showSearch: false,
    showMenu: false,
    user: null,
    hasApplicationsRead: false,
    hasUsersRead: false,
    hasTicketsRead: false,
    hasAutomationHealthRead: false,
    hasAccessReviewAdmin: false,
    hasOktaStatusRead: false,
})