<template>
<main v-if="apps !== null">
    <HeadingSubtitle icon="fa-solid fa-layer-group" text="Applications" />
    <hr />
    <span v-for="sortedApps, letter in apps" :key="letter" :id="letter">
      <HeadingSubtitle :text="letter" class="app-letter"></HeadingSubtitle>
      <span v-for="app in sortedApps" :key="app.id" class="app-link-wrapper">
        <PortalLink it="true" view="application" :item="app.id" class="app-link"><span>{{app.name}}</span></PortalLink>
      </span>
    </span>
</main>
</template>

<style scoped>
.app-link {
  display: inline-block;
  position: relative;
  vertical-align: top;
  background: #00C2E8;
  color: #fff;
  padding: 2vw;
  margin: 1px;
  min-width: 140px;
  min-height: 40px;
  text-align: center;
  font-family: 'Roboto', system-ui, -apple-system, BlinkMacSystemFont, 'Segoe UI', Roboto, Oxygen, Ubuntu, Cantarell, 'Open Sans', 'Helvetica Neue', sans-serif;
  transition: all ease .2s;
  animation: app-link-fade-in .25s ease-in-out 1 forwards;
  opacity: 0;
}

.app-link-wrapper:nth-child(odd) .app-link {
  animation-delay: .1s;
}

@keyframes app-link-fade-in {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.app-link:hover, .app-link:active {
  background: #00AEEF;
}

.app-link:active {
  transform: scale(.9);
}

.app-link span {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  font-size: 14px;
}

.app-letter {
  margin-top: 20px;
  font-size: 1.5em;
}

</style>

<script>
import {fetchFromApi} from '@/services/AppTools.js'
import PortalLink from '@/components/PortalLink.vue';
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';

export default {
  name: 'AllAppsView',
  components: {
    PortalLink, HeadingSubtitle
  },
  data() {
    return {
      apps: null,
    };
  },
  async beforeMount() {
    this.$root.loading = true;
    let apps = await fetchFromApi(this, '/api/application');

    // organize apps by first letter
    this.apps = apps.reduce((acc, app) => {
      const firstLetter = app.name.charAt(0).toUpperCase();
      if (!acc[firstLetter]) {
        acc[firstLetter] = [];
      }
      acc[firstLetter].push(app);
      return acc;
    }, {});

    console.log(this.apps)

    this.$root.loading = false;
  }
}
</script>
