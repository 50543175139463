<template>
  <div class="yuho-overlay">
    <img id="zio" src="../../public/static/yuho1.jpg" height="500">
  </div>
</template>

<script>
  export default {
    name: 'LoadingOverlay',
    props: ['loading']
  };
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style lang="scss" scoped>
 
.yuho-overlay {
  display: flex;
  position: fixed;
  top:0;
  left:0;
  opacity: 0.9;
  background: white;
  height: 100%;
  width:100%;
  z-index: 1000;
  justify-content: center;
  align-items: center;
}


@-webkit-keyframes pulse {
  0% { opacity: 0.2; }
  50% { opacity: 1; }
  100% { opacity: 0.2; }
}

@keyframes pulse {
    0% { opacity: 0.2; }
    50% { opacity: 1; }
    100% { opacity: 0.2; }
}

#zio {
    width: 300px;
    height: 300px;

    -webkit-animation: pulse 3s infinite ease-in-out;
    -o-animation: pulse 3s infinite ease-in-out;
    -ms-animation: pulse 3s infinite ease-in-out; 
    -moz-animation: pulse 3s infinite ease-in-out; 
    animation: pulse 3s infinite ease-in-out;
}
</style>