<template>
  <router-link :to="to()"><slot /></router-link>
</template>

<script>
export default {
  name: 'PortalLink',
  props: ['view', 'item', 'it'],
  methods: {
    to() {
      if(this.item){
        return '/' + this.view + '/' + this.item
      }

      return '/' + this.view
    }
  }
};
</script>s
