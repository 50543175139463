<template>
  <div :class="appClass">  
    <PageHeader :userPermissions="permissions" />
    <LoadingBar :loading="loading" />
    <AppMenu />
    <router-view/>
    <LoadingOverlay v-if="showLoadingOverlay" />
    <ErrorOverlay :error="error" @close="error = null" v-if="error" />
  </div>
</template>

<script>
import PageHeader from '@/components/header/PageHeader.vue'
import AppMenu from '@/components/AppMenu.vue';
import LoadingOverlay from '@/components/LoadingOverlay.vue';
import ErrorOverlay from '@/components/ErrorOverlay.vue';
import LoadingBar from '@/components/LoadingBar.vue'

import {authenticate} from '@/services/AppTools'
import {permissions} from '@/services/Permissions'
import {getSignedIn} from '@/services/SecurityService';

export default {
  name: "AppRoot",
  components: {
    PageHeader,
    AppMenu,
    LoadingOverlay,
    ErrorOverlay,
    LoadingBar
  },
  data() {
    return {
      loading: false,
      permissions: permissions,
      showLoadingOverlay: false,
      error: null,
      appClass: {},
    };
  }, 
  async beforeMount() {
    const isSignedIn = await getSignedIn();
    if(isSignedIn) {
      // load additional user info from backend api
      let done = false;
      
      setTimeout(() => {
        if(!done) {
          this.showLoadingOverlay = true;
        }
      }, 100)

      const user = await authenticate(this);
      if(user) {
        permissions.user = user;
        permissions.isIT = (user.permissions & 0xFFFFF) == 0xFFFFF;
        permissions.hasUsersRead = (user.permissions & 1 << 0) > 0;
        permissions.hasTicketsRead = (user.permissions & 1 << 2) > 0;
        permissions.hasApplicationsRead = (user.permissions & 1 << 3) > 0;
        permissions.hasAutomationHealthRead = (user.permissions & 1 << 4) > 0;
        permissions.hasOktaStatusRead = (user.permissions & 1 << 6) > 0;
        permissions.hasAccessReviewAdmin = (user.permissions & 1 << 22) > 0;
        
        permissions.showSearch = permissions.hasTicketsRead || permissions.hasApplicationsRead || permissions.hasUsersRead;
      }
      const portalTheme = window.localStorage.getItem("it-portal-theme");
      if(portalTheme && user.environment == "prod") {
        this.appClass[portalTheme] = true;
      } else {
        this.appClass["env-" + user.environment] = true;
      }
      
      done = true;
      this.showLoadingOverlay = false;
    }
  }
}
</script>

<style lang="scss">
  @import "~@/styles/global.scss";

  #app {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
  }

  // Header shadow effect when scrolled down
  html:not([data-scroll='0']) #head-wide,
  html:not([data-scroll='0']) #head-compact {
    box-shadow: 0px 1px 0px rgba(0, 0, 0, 0.1), 
                0px 0px 6px rgba(0, 0, 0, 0.1);
      
    /* This box-shadow will help sell the floating effect */
    box-shadow: 0 0 0.5em rgba(0, 0, 0, 0.5);
  }
</style>
