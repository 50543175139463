<template>
    {{label}}
    <label class="switch">
        <input type="checkbox" :checked="modelValue" @change="$emit('update:modelValue', $event.target.checked), $emit('change')">
        <span class="slider round"></span>
    </label>
</template>

<script>
    export default {
        name: "ToogleSwitch",
        props: ['modelValue', 'label'],
        emits: ['update:modelValue', 'change'],
    }
</script>

<style lang="scss">
// Toggle switch checkbox
.switch {
  transform: scale(0.8);
  position: relative;
  display: inline-block;
  width: 60px;
  height: 34px;

    /* Hide default HTML checkbox */
  input {
    opacity: 0;
    width: 0;
    height: 0;
  }

    /* The slider */
  .slider {
    position: absolute;
    cursor: pointer;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: #ccc;
    -webkit-transition: .4s;
    transition: .4s;
  }

  .slider:before {
    position: absolute;
    content: "";
    height: 26px;
    width: 26px;
    left: 4px;
    bottom: 4px;
    background-color: white;
    -webkit-transition: .4s;
    transition: .4s;
  }

  input:checked + .slider {
    /* background-color: #2196F3; */
    background-color: var(--theme-accent);
  }

  input:focus + .slider {
    /* box-shadow: 0 0 1px #2196F3; */
    box-shadow: 0 0 1px var(--theme-accent);
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  /* Rounded sliders */
  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
    }
}
</style>
