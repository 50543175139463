<template>
<main>
  <HeadingSubtitle text="Network data test" icon="fa-solid fa-building-shield" />
  <hr />
  
  <h4 class="subtitle">Network data test</h4>
  <table class="data-table">
    <thead>
      <tr class="data-header">
        <th>ID</th>
        <th>Data</th>
      </tr>
    </thead>
    <tbody>
      <template v-for="row in networks" :key="row.id">
        <tr class="data-row">
          <td>{{row.id}}</td>
          <td><pre>{{row.data}}</pre></td>
        </tr>
      </template>
      <tr class="data-row nodata" v-if="openReviews && openReviews.length == 0">
        <td colspan="4">Nothing here!</td>
      </tr>
    </tbody>
  </table>
</main>
</template>

<script>
import {fetchFromApi, formatDateTime} from '@/services/AppTools.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';

export default {
  name: 'ReviewsView',
  components: {
    HeadingSubtitle,
  },
  data() {
    return {
      networks: null
    };
  },
  async beforeMount() {
    this.$root.loading = true;
    this.networks = await fetchFromApi(this, '/api/networks.get');
    this.$root.loading = false;
  }
}
</script>

<style scoped lang="scss">
  h4.subtitle {
    font-weight: 900;
    font-size: 20px;
    margin-bottom: 1ex;
    margin-top: 2em;
  }

  .data-table {
    margin-top: 0ex;

    tr {
      transition: all 0.2s ease-in-out;
    }

    tr.closed {
      color: #999;
    }
  }
</style>