<template>
<main>
  <HeadingSubtitle icon="fa-solid fa-user" text="Users" class="view-title" />
  <hr />
  <ShortParagraph text="This is the Users page, where you can search for users, view their details and find links to third-party services." />

  <div class="user-search-hint">
    <ShortParagraph text="You can also search for users right here!" class="user-search-hint-p" />
    <i class="fa-solid fa-turn-up"></i>
  </div>

  <HeadingSubtitle style="margin: .25em 0" icon="fa-solid fa-exclamation-triangle"
                   text="You have no permissions to view this page." v-if="!permissions.user" />

  <router-link :to="'/user/' + permissions.user.id" v-if="permissions.user">
    <GenericButton icon="fa-solid fa-user-tag" :text="'View my account (' + permissions.user.firstname + ')'" style="margin: .25em 0" />
  </router-link>

  <ShortParagraph text="You can also search for users by clicking the magnifier icon on the page header." class="user-search-hint-mobile" v-if="permissions.user" />
  <p>
    <PortalLink view="user/onboardings" v-if="permissions.user">Current on-boardings</PortalLink>
  </p>.
</main>
</template>

<style scoped>
.user-search-hint {
  position: absolute;
  left: calc(50% - 16em);
  top: 70px;
  z-index: 100;
  padding: 5px;
  color: #007aff;
  animation: bounce 1s linear infinite;
  opacity: .7;
  transition: all ease .2s;
}

@keyframes bounce {
  0% {
    transform: translateY(0);
    opacity: .7;
  }
  50% {
    transform: translateY(-5px);
    opacity: .9;
  }
  100% {
    transform: translateY(0);
    opacity: .7;
  }
}

.user-search-hint-p {
  display: inline-block;
  margin: 0 10px 0 0;
  font-weight: bolder;
  vertical-align: middle;
  max-width: 200px;
  text-align: right;
}

.user-search-hint i {
  display: inline-block;
  font-size: .75em;
  vertical-align: top;
}

.user-search-hint-mobile {
  display: none;
}

@media screen and (max-width: 1100px) {
  .user-search-hint {
    left: calc(50% - 86px);
  }
}

@media screen and (max-width: 600px) {
  .user-search-hint {
    display: none;
  }

  .user-search-hint-mobile {
    display: block;
  }
}

</style>

<script>
import {permissions} from '@/services/Permissions.js'
import HeadingSubtitle from '@/components/objects/text/HeadingSubtitle.vue';
import ShortParagraph from '@/components/objects/text/ShortParagraph.vue';
import GenericButton from '@/components/objects/buttons/GenericButton.vue';
import PortalLink from '@/components/PortalLink.vue';

export default {
  name: 'UsersView',
  components: {
    HeadingSubtitle,
    ShortParagraph,
    GenericButton,
    PortalLink,
  },
  data() {
    return {
      permissions: permissions
    };
  },
  methods: {
    focusSearch(){
      document.getElementById("orgchartsearch").focus();
    }
  }
}
</script>
