import { createApp, Vue } from 'vue'
import App from './App.vue'
import router from './router'
import {getSignedIn, getUser} from './services/SecurityService';
import ClickOutside from './mixins/ClickOutsideDirective'; // Adjust the import path according to your project structure

//import titleMixin from './mixins/titleMixin'
const app = createApp(App)
app.use(router).mount('#app')

//Vue.mixin(titleMixin)
app.directive('click-outside', ClickOutside);

router.beforeEach((to, from, next) => {
    const requiresAuth = to.matched.some(record => record.meta.requiresAuth);
    if (requiresAuth) {
        getSignedIn().then(
            signIn => {
                if(!signIn) {
                    next({path: '/login', query: { 'ref': to.fullPath }});
                } else {
                    next();                   
                }
            },
            err => {
                console.log(err);
                next('/login');
            }
        );
    } else {
      next();
    }
  });


// Function to dynamically change CSS based on scroll position.
// ex. html:not([data-scroll='0']) header { ... }
// https://pqina.nl/blog/applying-styles-based-on-the-user-scroll-position-with-smart-css/

// The debounce function receives our function as a parameter
const debounce = (fn) => {
    // This holds the requestAnimationFrame reference, so we can cancel it if we wish
    let frame;

    // The debounce function returns a new function that can receive a variable number of arguments
    return (...params) => {
        // If the frame variable has been defined, clear it now, and queue for next frame
        if (frame) {
            cancelAnimationFrame(frame);
        }

        // Queue our function call for the next frame
        frame = requestAnimationFrame(() => {
            // Call our function and pass any params we received
            fn(...params);
        });
    };
};

// Reads out the scroll position and stores it in the data attribute
// so we can use it in our stylesheets
const storeScroll = () => {
    document.documentElement.dataset.scroll = window.scrollY;
};

// Listen for new scroll events, here we debounce our `storeScroll` function
document.addEventListener('scroll', debounce(storeScroll));

// Update scroll position for first time
storeScroll();